/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import './form.css'; // make sure to link to the CSS file
import IconButton from '@mui/material/IconButton';
import AddBoxRoundedIcon from '@mui/icons-material/AddBoxRounded';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import { TextField, Typography } from '@mui/material';
import Button from '@mui/material/Button';

import withLayoutOverlay from '../layout';
import ConfirmDialog from './confirmDialog';
import { setLoading } from '../user/reducer';
import { failureAlert, successAlert } from '../alert/reducer';
import * as api from './api';
import { S3_REGIONS } from '../../constants';

const ClientForm = () => {
  const { clientId } = useParams();
  const location = useLocation();
  const client = (location.state || {}).client || {};
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [clientDetails, setClientDetails] = useState({
    companyName: client.companyName || '',
    directorName: client.directorName || '',
    website: client.website || '',
    email: client.email || '',
    mobile: client.mobile || '',
    gstin: client.gstin || '',
    location: client.location || '',
    s3Region: client.s3Region || 'ap-south-1',
    bucketName: client.bucketName || '',
    isIpLoginEnabled: client.isIpLoginEnabled || false,
    ipAddresses: client.ipAddresses || [],
    profilePicture: client.profilePicture || null
  });
  const [newProfilePic, setNewProfilePic] = useState(null);
  const [ipAddress, setIpAddress] = useState('');
  const [error, setError] = useState({});
  const [bucketList, setBucketList] = useState([]);
  const [confirmIpDeleteDialog, setConfirmIpDeleteDialog] = useState(false);
  const [ipToDelete, setIpToDelete] = useState('');

  useEffect(() => {
    fetchExistingBuckets();
  }, []);

  const fetchExistingBuckets = async () => {
    try {
      if (clientId) {
        const response = await api.getBucketsList();
        setBucketList(response.buckets);
      }
    } catch (error) {
      dispatch(failureAlert(error.message));
    }
  };

  const handleChange = (e) => {
    setClientDetails({ ...clientDetails, [e.target.name]: e.target.value });
  };

  const handleIpLoginChange = () => {
    setClientDetails({ ...clientDetails, isIpLoginEnabled: !clientDetails.isIpLoginEnabled });
  };

  const handleImageChange = (e) => {
    setNewProfilePic(e.target.files[0]);
  };

  const updateIpAddress = (e) => {
    if (clientDetails.ipAddresses.includes(ipAddress)) {
      dispatch(failureAlert('IP address already exists'));
      setIpAddress('');
      return;
    }
    setClientDetails({ ...clientDetails, ipAddresses: [...clientDetails.ipAddresses, ipAddress] });
    setIpAddress('');
  };

  const confirmIpAddressDeletion = (ip) => {
    setConfirmIpDeleteDialog(true);
    setIpToDelete(ip);
  };

  const removeIpAddress = (ip) => {
    setClientDetails({ ...clientDetails, ipAddresses: [...clientDetails.ipAddresses].filter((add) => add !== ip) });
    setConfirmIpDeleteDialog(false);
    setIpToDelete('');
  };

  const isValidBucketName = (name) => {
    const bucketNameRegex = /^(?!^(\d+\.\d+\.\d+\.\d+)$)(^\w[\w.-]{1,61}\w$)/;

    // Check if the bucket name is between 3 and 63 characters
    if (name.length < 3 || name.length > 63 || !bucketNameRegex.test(name)) return false;

    return true;
  };

  const validate = () => {
    let isValid = true;
    const error = {};
    if (!clientDetails.companyName) {
      isValid = false;
      error.companyName = 'Company name is required';
    }
    if (clientDetails.email) {
      const isValidEmail = clientDetails.email.match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );

      if (!isValidEmail) {
        isValid = false;
        error.email = 'Email is invalid';
      }
    }

    if (!clientDetails.bucketName) {
      isValid = false;
      error.bucketName = 'Bucket name is required';
    } else {
      const isValidName = isValidBucketName(clientDetails.bucketName);
      if (!isValidName) {
        isValid = false;
        error.bucketName =
          'Invalid bucket name. Can only contain alphabets, numbers and only . (dot) or - (hyphen) symbols. And cannot start or end with symbols';
      }
    }

    if (!clientDetails.s3Region) {
      isValid = false;
      error.s3Region = 'S3 region is required';
    }

    setError(error);

    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const isValid = validate();

      if (!isValid) return;

      dispatch(setLoading(true));

      if (clientId) {
        const response = await api.update(clientId, clientDetails);
        if (newProfilePic) await api.uploadImage(response.client._id, newProfilePic);
        navigate(`/client/${clientId}`);
        dispatch(successAlert('Client updated successfully'));
      } else {
        const response = await api.create(clientDetails);
        if (newProfilePic) await api.uploadImage(response.client._id, newProfilePic);

        dispatch(successAlert('Client created successfully'));
        navigate('/');
      }
    } catch (error) {
      dispatch(failureAlert(error.message));
    }
    dispatch(setLoading(false));
  };

  return (
    <Container sx={{ flexGrow: 1, width: '100vw' }}>
      <Grid
        container
        sx={{
          flexGrow: 1,
          flexDirection: 'column',
          paddingTop: '40px',
          width: '100%'
        }}
        spacing={4}
      >
        <h2 className="heading">{clientId ? 'Edit' : 'Add New'} Client</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-row">
            <Grid item xs={12} sm={4}>
              <Paper elevation={0} sx={{ padding: '10px' }}>
                <div className="form-group">
                  <label htmlFor="companyName">Company Name <span style={{ color: 'red' }}>*</span></label>
                  <input
                    type="text"
                    id="companyName"
                    name="companyName"
                    value={clientDetails.companyName}
                    onChange={handleChange}
                  />
                  <span className="warning-msg">{error.companyName}</span>
                </div>

                <div className="form-group">
                  <label htmlFor="website">Website</label>
                  <input
                    type="text"
                    id="website"
                    name="website"
                    value={clientDetails.website}
                    onChange={handleChange}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="mobile">Mobile</label>
                  <input type="text" id="mobile" name="mobile" value={clientDetails.mobile} onChange={handleChange} />
                </div>

                <div className="form-group">
                  <label htmlFor="location">Location</label>
                  <input
                    type="text"
                    id="location"
                    name="location"
                    value={clientDetails.location}
                    onChange={handleChange}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="bucketName">S3 Bucket Name (Use lower case letters and numbers only) <span style={{ color: 'red' }}>*</span></label>

                  {clientId ? (
                    <select name="bucketName" id="bucketName" value={clientDetails.bucketName} onChange={handleChange}>
                      {bucketList.map((bucket) => (
                        <option key={bucket} value={bucket}>
                          {bucket}
                        </option>
                      ))}
                    </select>
                  ) : (
                    <input
                      type="text"
                      id="bucketName"
                      name="bucketName"
                      value={clientDetails.bucketName}
                      onChange={handleChange}
                    />
                  )}
                  <span className="warning-msg">{error.bucketName}</span>
                </div>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Paper elevation={0} sx={{ padding: '10px' }}>
                <div className="form-group">
                  <label htmlFor="directorName">Director Name</label>
                  <input
                    type="text"
                    id="directorName"
                    name="directorName"
                    value={clientDetails.directorName}
                    onChange={handleChange}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="email">Email</label>
                  <input type="text" id="email" name="email" value={clientDetails.email} onChange={handleChange} />
                  <span className="warning-msg">{error.email}</span>
                </div>

                <div className="form-group">
                  <label htmlFor="gstin">GSTIN</label>
                  <input type="text" id="gstin" name="gstin" value={clientDetails.gstin} onChange={handleChange} />
                </div>

                <div className="form-group">
                  <label htmlFor="s3Region">S3 Bucket Region</label>
                  <select
                    name="s3Region"
                    id="s3Region"
                    value={clientDetails.s3Region}
                    onChange={handleChange}
                    disabled={clientId}
                    style={{ width: '95%', padding: '9px' }}
                  >
                    {S3_REGIONS.map((r) => (
                      <option key={r.region} value={r.region}>
                        {r.label} {r.region}
                      </option>
                    ))}
                  </select>
                  <br />
                  <span className="warning-msg">{error.s3Region}</span>
                </div>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Paper elevation={0} sx={{ padding: '10px' }}>
                <div className="form-group">
                  <label htmlFor="newProfilePic">Profile Image</label>
                  <input type="file" id="newProfilePic" name="newProfilePic" onChange={handleImageChange} />
                </div>
              </Paper>
            </Grid>
          </div>
          <div className="form-row">
            <div className="form-column">
              <FormControl component="fieldset">
                <FormControlLabel
                  value="start"
                  control={
                    <Checkbox
                      onChange={handleIpLoginChange}
                      checked={clientDetails.isIpLoginEnabled}
                      sx={{ '&.Mui-checked': { color: '#53cbfa' } }}
                    />
                  }
                  label={
                    <Typography sx={{ color: '#5e5d5d', fontSize: '15px' }}>Enable IP address based login?</Typography>
                  }
                  labelPlacement="start"
                />
              </FormControl>
            </div>
            {clientDetails.isIpLoginEnabled && (
              <>
                <div className="form-column">
                  <Grid container direction="row" justifyContent="center" alignItems="center">
                    <Grid item>
                      <TextField
                        label={<Typography sx={{ color: '#5e5d5d', fontSize: '13px' }}>IP Address</Typography>}
                        variant="outlined"
                        value={ipAddress}
                        onChange={(e) => setIpAddress(e.target.value)}
                        inputProps={{
                          style: {
                            height: '9px',
                            width: '250px'
                          }
                        }}
                      />
                    </Grid>
                    <Grid item>
                      <IconButton aria-label="add new ip address" onClick={updateIpAddress}>
                        <AddBoxRoundedIcon sx={{ fontSize: 45, color: '#53cbfa' }} />
                      </IconButton>
                    </Grid>
                  </Grid>
                </div>
                <div className="form-column">
                  <Table sx={{ minWidth: 150 }} size="small" aria-label="ip-table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">Sl.no</TableCell>
                        <TableCell align="left">IP Address</TableCell>
                        <TableCell align="right"></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {clientDetails.ipAddresses.map((ip, index) => (
                        <TableRow key={index + ip} sx={{ '&:last-child td, &:last-child th': { border: 1 } }}>
                          {/* <TableCell component="th" scope="row">
                          {index + 1}
                        </TableCell> */}
                          <TableCell align="center">{index + 1}</TableCell>
                          <TableCell align="center">{ip}</TableCell>
                          <TableCell align="center" onClick={() => confirmIpAddressDeletion(ip)}>
                            <DeleteOutlineRoundedIcon sx={{ color: 'red' }} />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </div>
              </>
            )}
          </div>

          <div className="form-row">
            <div style={{ flex: 1 }}>
              <Button
                variant='outlined'
                onClick={() => navigate(-1)}
                sx={{
                  color: '#53cbfa',
                  borderColor: '#53cbfa',
                  textTransform: 'none',
                  '&:hover': {
                    borderColor: '#53cbfa',
                  },
                  padding: '5px 50px'
                }}>
                Cancel
              </Button>
            </div>
            <button type="submit">{clientId ? 'Update client' : 'Create client'}</button>
          </div>
        </form>
      </Grid>

      <ConfirmDialog
        open={confirmIpDeleteDialog}
        onCancel={() => setConfirmIpDeleteDialog(false)}
        title="Delete IP address?"
        text={`Confirm deleting IP address - ${ipToDelete}`}
        onConfirm={() => removeIpAddress(ipToDelete)}
      />
    </Container>
  );
};

export default withLayoutOverlay(ClientForm);
