import { configureStore } from '@reduxjs/toolkit';

import alertSlice from '../features/alert/reducer';
import userSlice from '../features/user/reducer';

export default configureStore({
  reducer: {
    alert: alertSlice,
    user: userSlice
  }
});
