import React, { useState } from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Divider from '@mui/material/Divider';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

const ObjectMenu = ({ object, cardType, openAction, duplicateObject, downloadObject, canModify }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const callAction = (action) => {
    openAction(action, cardType, object);
    setAnchorEl(null);
  };

  const onDuplicate = () => {
    duplicateObject(object, cardType);
    setAnchorEl(null);
  };

  const onDownload = () => {
    downloadObject(object, cardType);
    setAnchorEl(null);
  };

  return (
    <>
      <MoreVertIcon onClick={(e) => setAnchorEl(e.currentTarget)} />
      <Menu
        anchorEl={anchorEl}
        keepMounted
        onClose={() => setAnchorEl(null)}
        open={Boolean(anchorEl)}
        PaperProps={{ style: { borderRadius: '10px' } }}
      >
        {canModify && (
          <MenuItem sx={{ padding: '0px 30px 0px 30px' }} onClick={() => callAction('rename')}>
            Rename
          </MenuItem>
        )}
        {canModify && <Divider />}
        {canModify && (
          <MenuItem sx={{ padding: '0px 30px 0px 30px' }} onClick={onDuplicate}>
            Duplicate
          </MenuItem>
        )}
        {canModify && <Divider />}
        {canModify && (
          <MenuItem sx={{ padding: '0px 30px 0px 30px' }} onClick={() => callAction('move')}>
            Move
          </MenuItem>
        )}
        {canModify && <Divider />}
        <MenuItem sx={{ padding: '0px 30px 0px 30px' }} onClick={() => callAction('delete')}>
          Delete
        </MenuItem>
        <Divider component="li" />
        <MenuItem sx={{ padding: '0px 30px 0px 30px' }} onClick={onDownload}>
          Download
        </MenuItem>
        <Divider flexItem />
        <MenuItem sx={{ padding: '0px 30px 0px 30px' }} onClick={() => callAction('properties')}>
          Properties
        </MenuItem>
      </Menu>
    </>
  );
};

export default ObjectMenu;
