/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, memo } from 'react';
import { useDispatch } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import Button from '@mui/material/Button';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

import './login.css';

import * as api from './api';
import { failureAlert, successAlert } from '../alert/reducer';
import { setLoading } from '../user/reducer';

const ResetPassword = () => {
  const { token } = useParams();
  const payload = jwtDecode(token);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [error, setError] = useState({ password: '', confirmPassword: '' });

  const validate = () => {
    let isValid = true;
    const error = { confirmPassword: '', password: '' };
    if (!password) {
      isValid = false;
      error.password = 'Password is required';
    }

    if (!confirmPassword) {
      isValid = false;
      error.confirmPassword = 'Confirm password is required';
    }

    if (password !== confirmPassword) {
      isValid = false;
      error.confirmPassword = 'Confirm password does not match';
    }

    setError(error);

    return isValid;
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      const isValid = validate();

      if (!isValid) return;

      dispatch(setLoading(true));

      const response = await api.setPassword({ email: payload.email, password, token });

      dispatch(successAlert(response.message));
      navigateToLogin();
    } catch (error) {
      dispatch(failureAlert(error.message));
    }
    dispatch(setLoading(false));
  };

  const navigateToLogin = () => navigate('/');

  return (
    <Container
      component="main"
      sx={{
        height: '100vh',
        display: 'flex',
        alignItems: 'center'
      }}
    >
      <Paper
        elevation={0}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '100%',
          margin: 'auto',
          backgroundColor: 'rgba(255, 255, 255, 0.9)'
        }}
      >
        <Grid container sx={{ width: '100vw', height: '100vh' }}>
          <Grid
            item
            xs={12}
            sm={6}
            sx={{ backgroundColor: '#0c222a', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
          >
            <img src="/main-pic.png" alt="Math Head" style={{ height: '250px' }} />
            <div className="left-bottom">
              <p className="left-bottom-text">MathHead &#169; 2018 - 2024 / All Rights Reserved</p>
            </div>
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
            sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}
          >
            <div className="back-btn">
              <Button
                startIcon={<ArrowBackIosIcon />}
                style={{ color: '#807e7e', textTransform: 'none' }}
                onClick={navigateToLogin}
              >
                Back to login
              </Button>
            </div>
            <img src="/logo-name.png" alt="Math Head" className="logo-img" />

            <div className="form-area">
              <h2>Reset password</h2>

              {/* <p className="subtext">Reset your password</p> */}

              <form action="#" method="post">
                <div className="form-group">
                  <label htmlFor="password">Password</label>
                  <input
                    type="password"
                    id="password"
                    name="password"
                    required
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <span style={{ color: 'red', fontSize: '13px' }}>{error.password}</span>
                </div>
                <div className="form-group">
                  <label htmlFor="password">Confirm Password</label>
                  <input
                    type="text"
                    id="confirmPassword"
                    name="confirmPassword"
                    required
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                  <span style={{ color: 'red' }}>{error.confirmPassword}</span>
                </div>
                <Button
                  type="submit"
                  variant="contained"
                  onClick={onSubmit}
                  style={{ textTransform: 'none', backgroundColor: '#53cbfa', width: '100%' }}
                >
                  Submit
                </Button>
              </form>
            </div>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
};

export default memo(ResetPassword);
