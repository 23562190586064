import { createSlice } from '@reduxjs/toolkit';

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    user: null,
    loading: false,
    client: null,
    folder: null,
    isGridView: true
  },
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
      state.client = null;
      state.folder = null;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setClient: (state, action) => {
      state.client = action.payload;
      state.folder = null;
    },
    setFolder: (state, action) => {
      state.folder = action.payload;
    },
    setGridView: (state, action) => {
      state.isGridView = action.payload;
    }
  }
});

export const { setUser, setLoading, setClient, setFolder, setGridView } = userSlice.actions;

export default userSlice.reducer;
