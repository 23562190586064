import React from 'react';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

const RenameObject = ({ open, onClose, updateName, name, type, currentFolder }) => {
  let initialValue = name.replace(currentFolder, '');

  let extention = null;

  if (type !== 'folder') {
    const splitArray = initialValue.split('.');
    extention = splitArray.pop();
    initialValue = splitArray.join('.');
  } else {
    initialValue = initialValue.replace('/', '');
  }

  const onSubmit = (updatedName) => {
    const newName =
      type === 'folder' ? `${currentFolder}${updatedName}/` : `${currentFolder}${updatedName}.${extention}`;
    updateName(newName);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        component: 'form',
        onSubmit: (event) => {
          event.preventDefault();
          const formData = new FormData(event.currentTarget);
          const formJson = Object.fromEntries(formData.entries());
          const value = formJson.name;
          onSubmit(value);
        }
      }}
    >
      <DialogTitle>Rename folder</DialogTitle>
      <DialogContent sx={{ width: '300px' }}>
        <TextField
          autoFocus
          required
          margin="dense"
          id="name"
          name="name"
          label="Name"
          type="name"
          fullWidth
          variant="standard"
          defaultValue={initialValue}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button type="submit">Submit</Button>
      </DialogActions>
    </Dialog>
  );
};

export default RenameObject;
