/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Avatar from '@mui/material/Avatar';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import FormControlLabel from '@mui/material/FormControlLabel';
import Divider from '@mui/material/Divider';
import Switch from '@mui/material/Switch';
import { grey } from '@mui/material/colors';
import { Button, List, ListItem, ListItemAvatar, ListItemText, Typography, Grid } from '@mui/material';

import './view.css';
import withLayoutOverlay from '../layout';
import * as api from './api';
import { successAlert, failureAlert } from '../alert/reducer';
import { setLoading } from '../user/reducer';

const EmployeeView = () => {
  const { employeeId } = useParams();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [employee, setEmployee] = useState({});
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [isOpenDeactivateModal, setIsOpenDeactivateModal] = useState(false);
  const [isOpenPasswordModal, setIsOpenPasswordModal] = useState(false);
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');

  const fetchEmployeeDetails = async () => {
    try {
      dispatch(setLoading(true));
      const response = await api.getById(employeeId);
      setEmployee(response.employee);
    } catch (error) {
      dispatch(failureAlert(error.message));
    }
    dispatch(setLoading(false));
  };

  useEffect(() => {
    fetchEmployeeDetails();
  }, []);

  const deleteEmployee = async (e) => {
    e.preventDefault();
    try {
      dispatch(setLoading(true));

      await api.deleteEmployee(employeeId);
      dispatch(successAlert('Employee deleted successfully'));

      navigate(`/client/${employee.clientId._id}`);
    } catch (error) {
      dispatch(failureAlert(error.message));
    }
    dispatch(setLoading(false));
  };

  const deactivateEmployee = async (e) => {
    e.preventDefault();
    try {
      dispatch(setLoading(true));

      const response = await api.update(employeeId, { isDeactivated: !employee.isDeactivated });
      dispatch(successAlert(`Employee ${response.employee.isDeactivated ? 'deactivated' : 'activated'} successfully`));

      setEmployee(response.employee);
      setIsOpenDeactivateModal(false);
    } catch (error) {
      dispatch(failureAlert(error.message));
    }
    dispatch(setLoading(false));
  };

  const cancelPasswordUpdate = (e) => {
    e.preventDefault();
    setIsOpenPasswordModal(false);
    setPassword('');
    setPasswordError('');
  };

  const updatePassword = async () => {
    try {
      dispatch(setLoading(true));

      await api.update(employeeId, { password });
      dispatch(successAlert('Password updated successfully'));
      setIsOpenPasswordModal(false);
      setPassword('');
      setPasswordError('');
    } catch (error) {
      dispatch(failureAlert(error.message));
    }
    dispatch(setLoading(false));
  };

  if (!employee._id) return <h2>Loading</h2>;

  const name = employee.lastName ? employee.firstName + ' ' + employee.lastName : employee.firstName;
  return (
    <div className="user-profile-view">
      <div className="profile-section">
        <List>
          <ListItem
            alignItems="center"
            sx={{ padding: '10px 0px 20px 0px' }}
            secondaryAction={
              <Button
                variant="contained"
                endIcon={<EditOutlinedIcon />}
                onClick={() =>
                  navigate(`/employee/edit/${employeeId}`, {
                    state: { employee }
                  })
                }
                sx={{
                  borderRadius: '10px',
                  textTransform: 'none',
                  backgroundColor: '#53cbfa',
                  '&:hover': {
                    backgroundColor: '#53cbfa'
                  }
                }}
              >
                Edit
              </Button>
            }
          >
            <ListItemAvatar sx={{ paddingRight: '20px' }}>
              <Avatar sx={{ width: 130, height: 130 }} alt={name} src={employee.profilePicture}>
                {name
                  .split(' ')
                  .map((n) => n[0])
                  .join('')
                  .toUpperCase()}
              </Avatar>
            </ListItemAvatar>
            <Grid container direction="column">
              <ListItemText
                primary={<Typography variant="h5">{name}</Typography>}
              />
            </Grid>
          </ListItem>
        </List>
        <Divider />
        <List dense={false} sx={{ padding: '15px' }}>

          <ListItem style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ flex: 'none', width: 140, marginRight: 16 }}>
              <Typography variant="body2" color="#5e5d5d">
                Email
              </Typography>
            </div>
            <div style={{ flex: 'none', textAlign: 'right' }}>
              <Typography variant="body2">{employee.email}</Typography>
            </div>
          </ListItem>
          <ListItem style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ flex: 'none', width: 140, marginRight: 16 }}>
              <Typography variant="body2" color="#5e5d5d">
                Phone number
              </Typography>
            </div>
            <div style={{ flex: 'none', textAlign: 'right' }}>
              <Typography variant="body2">{employee.mobile}</Typography>
            </div>
          </ListItem>
          <ListItem style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ flex: 'none', width: 140, marginRight: 16 }}>
              <Typography variant="body2" color="#5e5d5d">
                Organization
              </Typography>
            </div>
            <div style={{ flex: 'none', textAlign: 'right' }}>
              <Typography variant="body2">{employee.clientId.companyName}</Typography>
            </div>
          </ListItem>
          <ListItem style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ flex: 'none', width: 140, marginRight: 16 }}>
              <Typography variant="body2" color="#5e5d5d">
                Role
              </Typography>
            </div>
            <div style={{ flex: 'none', textAlign: 'right' }}>
              <Typography variant="body2">{employee.role}</Typography>
            </div>
          </ListItem>
        </List>
        <Divider />

        <div style={{ display: 'flex', padding: '20px', justifyContent: 'space-between', alignItems: 'center' }}>
          <div>
            <Typography variant="h6">2 - step verification</Typography>
            <Typography variant="body1" color="#5e5d5d">
              Make the account extra secure. Along with the password need to enter a code sent to email
            </Typography>
          </div>
          <div>
            <FormControlLabel
              disabled
              control={
                <Switch
                  checked={employee.isMfaEnabled}
                  sx={{
                    width: 40,
                    height: 25,
                    padding: 0,
                    '& .MuiSwitch-switchBase': {
                      padding: 0,
                      margin: 0.2,
                      transitionDuration: '300ms',
                      '&.Mui-checked': {
                        transform: 'translateX(15px)',
                        color: '#fff',
                        '& + .MuiSwitch-track': {
                          backgroundColor: '#53cbfa',
                          opacity: 1,
                          border: 0,
                        },
                        '&.Mui-disabled + .MuiSwitch-track': {
                          opacity: 0.5,
                        },
                      },
                      '&.Mui-focusVisible .MuiSwitch-thumb': {
                        color: '#53cbfa',
                        border: '6px solid #fff',
                      },
                      '&.Mui-disabled .MuiSwitch-thumb': {
                        color: grey[400]
                      },
                      '&.Mui-disabled + .MuiSwitch-track': {
                        opacity: 0.7,
                      },
                    },
                    '& .MuiSwitch-thumb': {
                      boxSizing: 'border-box',
                      width: 22,
                      height: 22,
                    },
                    '& .MuiSwitch-track': {
                      borderRadius: 26 / 2,
                      backgroundColor: '#c7c7c7',
                      opacity: 1,
                    },
                  }}
                />
              } />
          </div>
        </div>
        <div style={{ display: 'flex', padding: '20px', justifyContent: 'space-between', alignItems: 'center' }}>
          <div>
            <Typography variant="h6">Password</Typography>
            <Typography variant="body1" color="#5e5d5d">
              Set unique password to protect the account
            </Typography>
          </div>
          <div>
            <button className="action-button" onClick={() => setIsOpenPasswordModal(true)}>
              Change Password
            </button>
          </div>
        </div>
        <Divider />

        <div style={{ display: 'flex', padding: '20px', justifyContent: 'space-between', alignItems: 'center' }}>
          <div>
            <Typography variant="h6">{employee.isDeactivated ? 'Activate' : 'De-activate'} my account</Typography>
            <Typography variant="body1" color="#5e5d5d">
              {employee.isDeactivated ? 'This will restore the account' : 'This will shut down the account.'}
            </Typography>
          </div>
          <div>
            <button className="action-button deactivate" onClick={() => setIsOpenDeactivateModal(true)}>
              {employee.isDeactivated ? 'Activate' : 'Deactivate'}
            </button>
          </div>
        </div>
        <Divider />
        <div style={{ display: 'flex', padding: '20px', justifyContent: 'space-between', alignItems: 'center' }}>
          <div>
            <Typography variant="h6">Delete my account</Typography>
            <Typography variant="body1" color="#5e5d5d">
              This will delete the account. Account will be permanently deleted from MathHead
            </Typography>
          </div>
          <div>
            <button className="action-button deactivate" onClick={() => setIsOpenDeleteModal(true)}>
              Delete
            </button>
          </div>
        </div>
        <Divider />
      </div>

      <DeactivateConfirmModal
        isDeactivated={employee.isDeactivated}
        isOpen={isOpenDeactivateModal}
        onCancel={() => setIsOpenDeactivateModal(false)}
        onConfirm={deactivateEmployee}
      />

      <DeleteConfirmModal
        isOpen={isOpenDeleteModal}
        onCancel={() => setIsOpenDeleteModal(false)}
        onConfirm={deleteEmployee}
      />

      <PasswordChangeModal
        isOpen={isOpenPasswordModal}
        onCancel={cancelPasswordUpdate}
        onConfirm={updatePassword}
        password={password}
        setPassword={setPassword}
        error={passwordError}
        setOtpError={setPasswordError}
      />
    </div>
  );
};

const DeleteConfirmModal = ({ isOpen, onCancel, onConfirm }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-backdrop">
      <div className="modal">
        <h2>Confirm employee delete</h2>
        <p>Are you sure you want to proceed?</p>
        <div className="cancel-confirm">
          <button onClick={onCancel} className="cancel-button">
            Cancel
          </button>

          <button onClick={onConfirm} className="confirm-button">
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
};

const DeactivateConfirmModal = ({ isOpen, onCancel, onConfirm, isDeactivated }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-backdrop">
      <div className="modal">
        <h2>Confirm employee {isDeactivated ? 'activation' : 'de-activation'}</h2>
        <p>Are you sure you want to proceed?</p>
        <div className="cancel-confirm">
          <button onClick={onCancel} className="cancel-button">
            Cancel
          </button>

          <button onClick={onConfirm} className="confirm-button">
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
};

const PasswordChangeModal = ({ isOpen, password, setPassword, error, setOtpError, onCancel, onConfirm }) => {
  if (!isOpen) return null;

  const onSubmit = (e) => {
    e.preventDefault();
    if (!password) {
      setOtpError('Password is required');
      return;
    }

    onConfirm();
  };

  return (
    <div className="modal-backdrop">
      <div className="modal">
        <h2>Change Password</h2>
        <div className="form-group">
          <input
            id="password"
            name="password"
            type="text"
            required
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <span style={{ color: 'red' }}>{error}</span>
        </div>
        <div className="cancel-confirm">
          <button onClick={onCancel} className="cancel-button">
            Cancel
          </button>

          <button onClick={onSubmit} className="confirm-button">
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default withLayoutOverlay(EmployeeView);
