import store from '../store';

const baseUrl = process.env.REACT_APP_BASE_URL;

const apiHandler = async (response) => {
  try {
    if (response === null || response === undefined) throw new Error('Request error');

    if (response.status >= 200 && response.status < 400) return await response.json();

    if (response.status === 401) {
      // logout user
      localStorage.removeItem('CFM-MH');
    }

    // throw error for other response
    const errorResponse = await response.json();

    throw new Error(errorResponse.message);
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const fetchHeader = () => {
  const user = (store.getState().user || {}).user;

  return {
    'Content-Type': 'application/json',
    Authorization: user ? `Bearer ${user.token}` : null
  };
};

const fetchImageHeader = () => {
  const user = (store.getState().user || {}).user;

  return {
    Authorization: `Bearer ${user.token}`
  };
};

export const get = async (url) => {
  const response = await fetch(`${baseUrl}${url}`, {
    method: 'GET',
    headers: fetchHeader()
  });

  return await apiHandler(response);
};

export const post = async (url, payload) => {
  const response = await fetch(`${baseUrl}${url}`, {
    method: 'POST',
    headers: fetchHeader(),
    body: JSON.stringify(payload)
  });

  return await apiHandler(response);
};

export const put = async (url, payload) => {
  const response = await fetch(`${baseUrl}${url}`, {
    method: 'PUT',
    headers: fetchHeader(),
    body: JSON.stringify(payload)
  });

  return await apiHandler(response);
};

export const destroy = async (url) => {
  const response = await fetch(`${baseUrl}/${url}`, {
    method: 'DELETE',
    headers: fetchHeader()
  });

  return await apiHandler(response);
};

export const postImage = async (url, file) => {
  const formData = new FormData();
  formData.append('file', file);

  const response = await fetch(`${baseUrl}${url}`, {
    method: 'POST',
    headers: fetchImageHeader(),
    body: formData
  });

  return await apiHandler(response);
};

export const getUser = async (token) => {
  const response = await fetch(`${baseUrl}/auth/user`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    }
  });

  return await apiHandler(response);
};
