import 'react-toastify/dist/ReactToastify.min.css';

import './index.css';
import React, { memo, useEffect, useCallback } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { useSelector } from 'react-redux';

const Alert = () => {
  const alert = useSelector((state) => state.alert).value;

  const updateToast = useCallback(() => {
    if (!alert) return;

    const { isSuccess, message = 'Something went wrong, please try again' } = alert || {};

    isSuccess ? toast.success(message) : toast.error(message);
  }, [alert]);

  useEffect(() => {
    updateToast();
  }, [alert, updateToast]);

  return (
    <ToastContainer
      className='toast-position'
      position="top-right"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="colored"
    />
  );
};

export default memo(Alert);
