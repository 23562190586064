import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import { useDispatch } from 'react-redux';
import Dropzone from 'react-dropzone';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import './fileUpload.css';
import { LinearProgress, Box, Typography } from '@mui/material';

import { getUploadUrls } from '../api';
import { failureAlert, successAlert } from '../../alert/reducer';
import { validateFileCountAndSize } from '../helper';
import ConfirmDialog from '../../client/confirmDialog';

export default function FileUpload({ open, onClose, clientId, currentFolder, onSuccess, isDuplicateFile }) {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [filesList, setfilesList] = useState([]);
  const [fileNames, setFilesName] = useState([]);
  const [showReplaceDialog, setShowReplaceDialog] = useState(false);

  const uploadToS3 = async (url, file) => {
    try {
      if (!url) {
        console.error('Upload URL not present');
        return;
      }

      await fetch(url, {
        method: 'PUT',
        headers: {
          'Content-Type': file.type
        },
        body: file
      });

      return true;
    } catch (error) {
      console.error('uploadToS3 ~ error:', error);
      return false;
    }
  };

  const uploadFiles = async (files) => {
    try {
      setIsLoading(true);
      let progress = 0;

      const interval = setInterval(() => {
        progress += 5;
        setUploadProgress(progress);

        if (progress === 100) clearInterval(interval);
      }, 500);

      const fileObjects = files.map((f) => {
        return {
          name: `${currentFolder || ''}${f.name}`,
          type: f.type
        };
      });

      const { urlList } = await getUploadUrls(fileObjects, clientId);

      const response = await Promise.all(
        files.map((file) => {
          const { url } = urlList.find((u) => u.name === `${currentFolder || ''}${file.name}`);
          return uploadToS3(url, file);
        })
      );

      if (response.every((r) => r)) {
        dispatch(successAlert('Uploaded files successfully'));
      } else {
        dispatch(failureAlert('Failed to upload'));
      }
    } catch (error) {
      console.error('handleChange ~ error:', error);
      dispatch(failureAlert('Failed to upload'));
    }
    onSuccess();
  };

  const handleChange = async (filesObject) => {
    const files = Object.keys(filesObject).map((i) => filesObject[i]);

    const err = validateFileCountAndSize(files);

    if (err) {
      dispatch(failureAlert(err));
      return;
    }

    const duplicateNames = files.filter((f) => isDuplicateFile(f.name)).map((f) => f.name);
    if (duplicateNames.length) {
      setFilesName(duplicateNames);
      setfilesList(files);
      setShowReplaceDialog(true);

      return;
    }

    uploadFiles(files);
  };

  const cancelUpload = () => {
    setShowReplaceDialog(false);
    onClose();
  };

  return (
    <Dialog
      onClose={onClose}
      open={open}
      maxWidth="lg"
      PaperProps={{ sx: { borderRadius: '10px', padding: '35px', alignItems: 'center' } }}
    >
      {isLoading ? (
        <Box mt={2} sx={{ padding: '15px', width: '500px' }}>
          <LinearProgress variant="determinate" value={uploadProgress} />
          <Typography variant="caption" color="textSecondary" mt={1}>
            {uploadProgress === 100 ? 'Upload Complete!' : `Uploading... ${uploadProgress}%`}
          </Typography>
        </Box>
      ) : (
        <Dropzone onDrop={handleChange}>
          {({ getRootProps, getInputProps }) => (
            <section className="container">
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                <img src="/drag-n-drop-folder.png" alt="open folder for files" width="70px" />
                <div className="dropbox-text">
                  <p> Drag your document, files or photos here to start uploading</p>
                  <Divider>OR</Divider>
                </div>
                <div className="browse-btn">
                  <Button
                    variant="contained"
                    style={{ textTransform: 'none', backgroundColor: '#53cbfa', borderRadius: '10px', width: '40%' }}
                  >
                    Browse Files
                  </Button>
                </div>
              </div>
            </section>
          )}
        </Dropzone>
      )}

      {showReplaceDialog && (
        <ConfirmDialog
          open={showReplaceDialog}
          onCancel={cancelUpload}
          title="Upload Options"
          text={`${
            fileNames.length > 1 ? 'There are few files with same name' : `"${fileNames[0]}"`
          } already exists in this location. Please choose one of the below options`}
          cancelText={'Cancel'}
          confirmText={'Replace'}
          onConfirm={() => uploadFiles(filesList)}
        />
      )}
    </Dialog>
  );
}
