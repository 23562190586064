import React, { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';
import CheckBoxOutlineBlankRoundedIcon from '@mui/icons-material/CheckBoxOutlineBlankRounded';
import CheckBoxRoundedIcon from '@mui/icons-material/CheckBoxRounded';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';

const MultiSelectOption = ({
  selectList,
  setSelectList,
  canModify,
  deleteMultipleObjects,
  downloadMultipleObjects,
  isSelectAll,
  onSelect,
  onUnSelect
}) => {
  const [openDialog, setOpenDialog] = useState(false);

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleDeleteClick = () => {
    setOpenDialog(true);
  }

  const handleDeleteConfirm = (e) => {
    deleteMultipleObjects(e);
    setOpenDialog(false);

  }
  if (!selectList.length) return null;
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" sx={{ backgroundColor: '#53cbfa' }}>
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={() => setSelectList([])}
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            {selectList.length} Selected
          </Typography>
          {isSelectAll ? (
            <div style={{ alignItems: "center", display: 'flex', marginRight: '20px' }}>
              <CheckBoxRoundedIcon onClick={onUnSelect} sx={{ marginRight: '5px' }} />
              <Typography variant="caption">
                Unselect All
              </Typography>
            </div>
          ) : (
            <div style={{ alignItems: "center", display: 'flex', marginRight: '20px' }}>
              <CheckBoxOutlineBlankRoundedIcon onClick={onSelect} sx={{ marginRight: '5px' }} />
              <Typography variant="caption">
                Select All
              </Typography>
            </div>
          )}
          {canModify && (
            <div>
              <Button
                variant="contained"
                elevation="4"
                endIcon={<DeleteIcon />}
                style={{
                  color: 'black',
                  textTransform: 'none',
                  backgroundColor: 'white',
                  borderRadius: '10px',
                  marginRight: '20px'
                }}
                onClick={handleDeleteClick}
              >
                Delete
              </Button>
              <Dialog
                open={openDialog}
                onClose={handleDialogClose}
                PaperProps={{ sx: { borderRadius: '20px', padding: '10px' } }}
              >
                <DialogTitle>
                  {`Are you sure you want to delete ${selectList.length} items?`}
                </DialogTitle>
                <DialogActions>
                  <Button onClick={handleDialogClose}>Cancel</Button>
                  <Button onClick={handleDeleteConfirm} autoFocus>
                    Delete
                  </Button>
                </DialogActions>
              </Dialog>
            </div>
          )}

          <Button
            variant="contained"
            elevation="4"
            endIcon={<DownloadIcon />}
            style={{
              color: 'black',
              textTransform: 'none',
              backgroundColor: 'white',
              borderRadius: '10px'
            }}
            onClick={downloadMultipleObjects}
          >
            Download
          </Button>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default MultiSelectOption;
