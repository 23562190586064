import React, { Component } from 'react';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch() {
    try {
      this.setState({ hasError: true });
    } catch (error) {
      console.error(error);
    }
  }

  render() {
    if (this.state.hasError) {
      return (
        <div>
          <center>Something went wrong</center>
          <center>
            <button onClick={() => window.location.reload()}>Reload</button>
          </center>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
