export const S3_REGIONS = [
  { region: 'us-east-1', label: 'US East (N. Virginia)' },
  { region: 'us-east-2', label: 'US East (Ohio)' },
  { region: 'us-west-1', label: 'US West (N. California)' },
  { region: 'us-west-2', label: 'US West (Oregon)' },
  { region: 'ap-south-1', label: 'Asia Pacific (Mumbai)' },
  { region: 'ap-northeast-1', label: 'Asia Pacific (Tokyo)' },
  { region: 'ap-northeast-2', label: 'Asia Pacific (Seoul)' },
  { region: 'ap-northeast-3', label: 'Asia Pacific (Osaka)' },
  { region: 'ap-southeast-1', label: 'Asia Pacific (Singapore)' },
  { region: 'ap-southeast-2', label: 'Asia Pacific (Sydney)' },
  { region: 'ca-central-1', label: 'Canada (Central)' },
  { region: 'eu-central-1', label: 'Europe (Frankfurt)' },
  { region: 'eu-west-1', label: 'Europe (Ireland)' },
  { region: 'eu-west-2', label: 'Europe (London)' },
  { region: 'eu-west-3', label: 'Europe (Paris)' },
  { region: 'eu-north-1', label: 'Europe (Stockholm)' },
  { region: 'sa-east-1', label: 'South America (São Paulo)' }
];

export const FilesUploadCountLimit = 250;

export const FilesUploadSizeLimitInMB = 250;

export const FilesDownloadCountLimit = 250;

export const FilesDownloadSizeLimitInMB = 250;
