import React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import { ListItemIcon, Typography } from '@mui/material';
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';

const ClientList = ({ clients, selectClient }) => {
  return (
    <div>
      <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper', padding: '30px' }}>
        {clients.map((client) => (
          <ListItem onClick={() => selectClient(client)} sx={{ padding: "10px" }}>
            <ListItemAvatar sx={{ padding: '10px' }}>
              <Avatar alt={client.companyName} src={client.profilePicture} sx={{ padding: '5px', fontSize: '15px' }}>
                {' '}
                {client.companyName
                  .split(' ')
                  .map((n) => n[0])
                  .join('')
                  .toUpperCase()}
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={client.companyName}
              secondary={
                <React.Fragment>
                  <div
                    style={{
                      flexDirection: 'row',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-start'
                    }}
                  >
                    <ListItemIcon sx={{ minWidth: '25px' }}>
                      <PeopleOutlinedIcon sx={{ fontSize: 18, padding: 0, margin: 0 }} />
                    </ListItemIcon>
                    <Typography>{`${client.employeeCount} Members`}</Typography>
                  </div>
                </React.Fragment>
              }
            />
          </ListItem>
        ))}
      </List>
    </div>
  );
};
export default ClientList;
