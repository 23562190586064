/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Avatar from '@mui/material/Avatar';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import Button from '@mui/material/Button';
import { List, ListItem, ListItemIcon, ListItemText, Paper } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import InventoryIcon from '@mui/icons-material/Inventory';
import SearchIcon from '@mui/icons-material/Search';
import { Input, InputAdornment } from '@mui/material';
import CheckBoxOutlineBlankRoundedIcon from '@mui/icons-material/CheckBoxOutlineBlankRounded';
import CheckBoxRoundedIcon from '@mui/icons-material/CheckBoxRounded';

import './view.css';
import withLayoutOverlay from '../layout';
import * as api from './api';
import { deleteEmployee } from '../employee/api';
import { successAlert, failureAlert } from '../alert/reducer';
import { setLoading } from '../user/reducer';
import { formatSize } from '../drive/helper';

function ClientView() {
  const { clientId } = useParams();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [client, setClient] = useState({});
  const [employees, setEmployees] = useState([]);
  const [bucketSize, setBucketSize] = useState(0);
  const [isOpenDeActivateModal, setIsOpenDeActivateModal] = useState(false);
  const [isDeactivateOtpSent, setIsDeactivateOtpSent] = useState(false);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [isDeleteOtpSent, setIsDeleteOtpSent] = useState(false);
  const [otp, setOtp] = useState('');
  const [otpError, setOtpError] = useState('');
  const [isDisabled, setIsDisabled] = useState(false);

  const fetchClientDetails = async () => {
    try {
      dispatch(setLoading(true));
      const response = await api.fetchByClient(clientId);
      setClient(response.client);
      setEmployees(response.employees);
      setBucketSize(response.bucketSize);
    } catch (error) {
      dispatch(failureAlert(error.message));
    }
    dispatch(setLoading(false));
  };

  useEffect(() => {
    fetchClientDetails();
  }, []);

  const sendDeleteOtp = async (e) => {
    e.preventDefault();
    try {
      dispatch(setLoading(true));

      await api.sendDeleteOtp(clientId);
      setIsDeleteOtpSent(true);
      dispatch(successAlert(`OTP sent successfully`));
    } catch (error) {
      dispatch(failureAlert(error.message));
    }
    dispatch(setLoading(false));
  };

  const deleteClient = async () => {
    try {
      if (isDisabled) return;
      dispatch(setLoading(true));
      setIsDisabled(true);

      await api.deleteById(clientId, { otp });

      dispatch(successAlert('Client deleted successfully'));

      navigate('/');
    } catch (error) {
      dispatch(failureAlert(error.message));
    }
    setIsDisabled(false);
    dispatch(setLoading(false));
  };

  const sendActiveStatusOtp = async (e) => {
    e.preventDefault();
    try {
      dispatch(setLoading(true));

      await api.sendActiveStatusOtp(clientId);
      setIsDeactivateOtpSent(true);
      dispatch(successAlert(`OTP sent successfully`));
    } catch (error) {
      dispatch(failureAlert(error.message));
    }
    dispatch(setLoading(false));
  };

  const updateActiveStatus = async () => {
    try {
      if (isDisabled) return;
      dispatch(setLoading(true));
      setIsDisabled(true);

      const response = await api.updateActiveStatus(clientId, { otp, isDeactivated: !client.isDeactivated });
      setClient(response.client);

      dispatch(successAlert(`Client ${response.client.isDeactivated ? 'de-activated' : 'activated'} successfully`));

      setOtp('');
      setIsDeactivateOtpSent(false);
      setIsOpenDeActivateModal(false);
      setIsDisabled(false);

      dispatch(setLoading(false));
    } catch (error) {
      dispatch(failureAlert(error.message));
    }
    setIsDisabled(false);
    dispatch(setLoading(false));
  };

  return (
    <div className="dashboard">
      <ClientDetails
        client={client}
        deleteAccount={() => setIsOpenDeleteModal(true)}
        activateAccount={() => setIsOpenDeActivateModal(true)}
        deActivateAccount={() => setIsOpenDeActivateModal(true)}
        bucketSize={bucketSize}
        isAccountActive={!client.isDeactivated}
      />
      <MemberList
        clientId={client._id}
        clientName={client.companyName}
        employees={employees}
        fetchClientDetails={fetchClientDetails}
      />

      <DeActivateConfirmModal
        isOpen={isOpenDeActivateModal}
        isDisabled={isDisabled}
        isAccountActive={!client.isDeactivated}
        isDeactivateOtpSent={isDeactivateOtpSent}
        onCancel={() => {
          setOtp('');
          setIsDeactivateOtpSent(false);
          setIsOpenDeActivateModal(false);
          setIsDisabled(false);
        }}
        onConfirm={sendActiveStatusOtp}
        otp={otp}
        setOtp={setOtp}
        error={otpError}
        setOtpError={setOtpError}
        resendOtp={sendActiveStatusOtp}
        updateActiveStatus={(otp) => updateActiveStatus(otp)}
      />

      <DeleteConfirmModal
        isOpen={isOpenDeleteModal}
        isDisabled={isDisabled}
        isDeleteOtpSent={isDeleteOtpSent}
        onCancel={() => {
          setOtp('');
          setIsDeleteOtpSent(false);
          setIsOpenDeleteModal(false);
          setIsDisabled(false);
        }}
        onConfirm={sendDeleteOtp}
        otp={otp}
        setOtp={setOtp}
        error={otpError}
        setOtpError={setOtpError}
        resendOtp={sendDeleteOtp}
        deleteClient={(otp) => deleteClient(otp)}
      />
    </div>
  );
}

const DeActivateConfirmModal = ({
  isOpen,
  isAccountActive,
  isDeactivateOtpSent,
  onCancel,
  onConfirm,
  otp,
  setOtp,
  error,
  setOtpError,
  resendOtp,
  updateActiveStatus,
  isDisabled
}) => {
  if (!isOpen) return null;

  const onSubmit = (e) => {
    e.preventDefault();
    if (!otp) {
      setOtpError('OTP is required');
      return;
    }
    updateActiveStatus();
  };

  return (
    <div className="modal-backdrop">
      <div className="modal">
        <h2>Confirm client {isAccountActive ? 'de-activation' : 'activation'}</h2>
        {isDeactivateOtpSent ? (
          <div className="form-group">
            <label htmlFor="otp">OTP</label>
            <input id="otp" name="otp" type="number" required value={otp} onChange={(e) => setOtp(+e.target.value)} />
            <span style={{ color: 'red' }}>{error}</span>
            <p>
              Didn't receive the OTP?{' '}
              <a href="#" className="contact-us" onClick={resendOtp}>
                Resend OTP
              </a>
            </p>
          </div>
        ) : (
          <p>Are you sure you want to proceed?</p>
        )}
        <div className="cancel-confirm">
          <button onClick={onCancel} className="cancel-button">
            Cancel
          </button>
          {isDeactivateOtpSent ? (
            <button onClick={onSubmit} className="confirm-button" disabled={isDisabled}>
              Submit
            </button>
          ) : (
            <button onClick={onConfirm} className="confirm-button">
              Confirm
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

const DeleteConfirmModal = ({
  isOpen,
  isDisabled,
  isDeleteOtpSent,
  onCancel,
  onConfirm,
  otp,
  setOtp,
  error,
  setOtpError,
  resendOtp,
  deleteClient
}) => {
  if (!isOpen) return null;

  const onSubmit = (e) => {
    e.preventDefault();
    if (!otp) {
      setOtpError('OTP is required');
      return;
    }
    deleteClient();
  };

  return (
    <div className="modal-backdrop">
      <div className="modal">
        <h2>Confirm client delete</h2>
        {isDeleteOtpSent ? (
          <div className="form-group">
            <label htmlFor="otp">OTP</label>
            <input id="otp" name="otp" type="number" required value={otp} onChange={(e) => setOtp(+e.target.value)} />
            <span style={{ color: 'red' }}>{error}</span>
            <p>
              Didn't receive the OTP?{' '}
              <a href="#" className="contact-us" onClick={resendOtp}>
                Resend OTP
              </a>
            </p>
          </div>
        ) : (
          <p>Are you sure you want to proceed?</p>
        )}
        <div className="cancel-confirm">
          <button onClick={onCancel} className="cancel-button">
            Cancel
          </button>
          {isDeleteOtpSent ? (
            <button onClick={onSubmit} className="confirm-button" disabled={isDisabled}>
              Submit
            </button>
          ) : (
            <button onClick={onConfirm} className="confirm-button">
              Confirm
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

function ClientDetails({ client, deleteAccount, activateAccount, deActivateAccount, bucketSize, isAccountActive }) {
  return (
    <div className="client-details">
      <div className="details-section">
        <Avatar sx={{ width: 200, height: 200 }} alt={client.companyName} src={client.profilePicture}></Avatar>
      </div>
      <div className="details-section">
        <ClientInfo
          client={client}
          deleteAccount={deleteAccount}
          activateAccount={activateAccount}
          deActivateAccount={deActivateAccount}
        />
      </div>
      <div className="details-section">
        <StorageChart clientId={client._id} bucketSize={bucketSize} isAccountActive={isAccountActive} />
      </div>
    </div>
  );
}

// ClientInfo Component with updated header

function ClientInfo({ client, deleteAccount, activateAccount, deActivateAccount }) {
  const navigate = useNavigate();

  return (
    <div className="client-info">
      <div className="client-info-header">
        <h3>Details</h3>
        <Button
          variant="outlined"
          endIcon={<EditOutlinedIcon />}
          className="edit-button"
          onClick={() =>
            navigate(`/client/edit/${client._id}`, {
              state: { client }
            })
          }
          sx={{
            textTransform: 'none',
            borderRadius: '10px',
            color: '#53cbfa',
            borderColor: '#53cbfa',
            '&:hover': {
              borderColor: '#53cbfa'
            }
          }}
        >
          Edit
        </Button>
      </div>
      <List dense={true}>
        <ListItem style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ flex: 'none', width: 140, marginRight: 16 }}>
            <Typography variant="body2">
              <strong>Company Name</strong>
            </Typography>
          </div>
          <div style={{ flex: 'none', textAlign: 'right' }}>
            <Typography variant="body2">{client.companyName}</Typography>
          </div>
        </ListItem>
        <ListItem>
          <div style={{ flex: 'none', width: 140, marginRight: 16 }}>
            <Typography variant="body2">
              <strong>Director Name</strong>
            </Typography>
          </div>
          <div style={{ flex: 'none', textAlign: 'right' }}>
            <Typography variant="body2">{client.directorName || '-'}</Typography>
          </div>
        </ListItem>
        <ListItem>
          <div style={{ flex: 'none', width: 140, marginRight: 16 }}>
            <Typography variant="body2">
              <strong>Website</strong>
            </Typography>
          </div>
          <div style={{ flex: 'none', textAlign: 'right' }}>
            <Typography variant="body2">{client.website || '-'}</Typography>
          </div>
        </ListItem>
        <ListItem>
          <div style={{ flex: 'none', width: 140, marginRight: 16 }}>
            <Typography variant="body2">
              <strong>Email</strong>
            </Typography>
          </div>
          <div style={{ flex: 'none', textAlign: 'right' }}>
            <Typography variant="body2">{client.email || '-'}</Typography>
          </div>
        </ListItem>
        <ListItem>
          <div style={{ flex: 'none', width: 140, marginRight: 16 }}>
            <Typography variant="body2">
              <strong>Location</strong>
            </Typography>
          </div>
          <div style={{ flex: 'none', textAlign: 'right' }}>
            <Typography variant="body2">{client.location || '-'}</Typography>
          </div>
        </ListItem>
      </List>

      <div className="details-footer">
        <Button
          variant="outlined"
          startIcon={<DeleteOutlineRoundedIcon />}
          onClick={deleteAccount}
          sx={{
            m: 1,
            whiteSpace: 'nowrap',
            borderRadius: '10px',
            textTransform: 'none',
            color: '#53cbfa',
            borderColor: '#53cbfa',
            '&:hover': {
              borderColor: '#53cbfa'
            }
          }}
        >
          Delete Client
        </Button>
        {client.isDeactivated ? (
          <Button
            variant="outlined"
            onClick={activateAccount}
            sx={{
              m: 1,
              whiteSpace: 'nowrap',
              borderRadius: '10px',
              textTransform: 'none',
              color: '#53cbfa',
              borderColor: '#53cbfa',
              '&:hover': {
                borderColor: '#53cbfa'
              }
            }}
          >
            Activate Client
          </Button>
        ) : (
          <Button
            variant="outlined"
            onClick={deActivateAccount}
            sx={{
              m: 1,
              whiteSpace: 'nowrap',
              borderRadius: '10px',
              textTransform: 'none',
              color: '#53cbfa',
              borderColor: '#53cbfa',
              '&:hover': {
                borderColor: '#53cbfa'
              }
            }}
          >
            De-activate Client
          </Button>
        )}
      </div>
    </div>
  );
}

function StorageChart({ clientId, bucketSize, isAccountActive }) {
  const navigate = useNavigate();
  return (
    <div className="storage-chart-container">
      <ListItem disablePadding>
        <ListItemIcon sx={{ marginRight: 0, padding: 0 }}>
          <InventoryIcon sx={{ color: '#53cbfa' }} />
        </ListItemIcon>
        <ListItemText primary="Storage Occupied" />
      </ListItem>

      <div className="circle-text-container">
        <div className="circle-text">
          <span className="larger-text">{bucketSize === 0 ? 0 : formatSize(bucketSize)}</span>
        </div>
      </div>

      <Button
        variant="contained"
        className="goto-drive-btn"
        onClick={() =>
          navigate(`/drive/${clientId}`, {
            state: { clientId, isAccountActive }
          })
        }
        sx={{
          borderRadius: '10px',
          textTransform: 'none',
          backgroundColor: '#53cbfa',
          '&:hover': {
            backgroundColor: '#53cbfa'
          }
        }}
      >
        Go to drive
      </Button>
    </div>
  );
}

function MemberList({ clientId, clientName, employees, fetchClientDetails }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [selectList, setSelectList] = useState([]);
  const [search, setSearch] = useState('');
  const [searchedEmployees, setSearchedEmployees] = useState(employees);

  useEffect(() => {
    if (!search) {
      setSearchedEmployees(employees);
      return;
    }
    const results = employees.filter((emp) =>
      ((emp.firstName || '') + (emp.lastName || '')).toLowerCase().includes(search.toLowerCase())
    );
    setSearchedEmployees(results);
  }, [search, employees]);

  const deleteEmployees = async (e) => {
    e.preventDefault();
    try {
      await Promise.all(selectList.map((l) => deleteEmployee(l)));
      dispatch(successAlert('Employees deleted successfully'));
      setSelectList([]);
      fetchClientDetails();
    } catch (error) {
      dispatch(failureAlert('Failed to delete employees'));
    }
  };

  return (
    <div className="member-list-container">
      <div className="member-list-header">
        <Input
          sx={{ width: '30%', border: '1px solid #ccc', borderRadius: '10px', padding: '3px 3px 3px 10px' }}
          placeholder="Search member"
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          }
          disableUnderline
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />

        <Button
          variant="contained"
          onClick={() => navigate(`/employee/new/${clientId}`)}
          sx={{
            m: 1,
            whiteSpace: 'nowrap',
            borderRadius: '10px',
            textTransform: 'none',
            backgroundColor: '#53cbfa',
            '&:hover': {
              backgroundColor: '#53cbfa'
            }
          }}
        >
          Add member
        </Button>

        {!!selectList.length && (
          <Button
            variant="contained"
            onClick={deleteEmployees}
            sx={{
              m: 1,
              whiteSpace: 'nowrap',
              borderRadius: '10px',
              textTransform: 'none'
            }}
            color="error"
          >
            Delete {selectList.length} members
          </Button>
        )}
      </div>

      <div className="member-list">
        {!searchedEmployees.length ? (
          <div style={{ width: '100%', alignItems: 'center', display: 'flex', flexDirection: 'column', paddingTop: '20px' }}>
            {search ? <img src="/unsuccessful-search.png" alt="No Results" /> : <img src="/employees.png" alt="No Results" />}
            <p style={{ color: '#5e5d5d', padding: '10px' }}>
              {search ? `Your search term "${search}" does not match with any employees` : 'Start creating employees'}
            </p>
          </div>
        ) : null}

        {searchedEmployees.map((employee) => (
          <MemberCard
            key={employee._id}
            clientName={clientName}
            employee={employee}
            isSelected={selectList.includes(employee._id)}
            onSelect={() => setSelectList([...selectList, employee._id])}
            onUnSelect={() => setSelectList(selectList.filter((l) => l !== employee._id))}
            onClickEdit={() =>
              navigate(`/employee/edit/${employee._id}`, {
                state: { employee }
              })
            }
          />
        ))}
      </div>
    </div>
  );
}

function MemberCard({ employee, onClickEdit, isSelected, onUnSelect, onSelect }) {
  const navigate = useNavigate();
  const name = employee.lastName ? employee.firstName + ' ' + employee.lastName : employee.firstName;

  function truncate(source, size) {
    return source.length > size ? source.slice(0, size - 1) + '…' : source;
  }

  return (
    <Paper
      elevation={4}
      sx={{
        borderRadius: '15px',
        flexDirection: 'column',
        textAlign: 'center',
        padding: '20px 5px 20px 5px',
        display: 'flex'
      }}
    >
      <div className="edit-btn">
        {isSelected ? (
          <CheckBoxRoundedIcon onClick={onUnSelect} sx={{ color: '#53cbfa' }} />
        ) : (
          <CheckBoxOutlineBlankRoundedIcon onClick={onSelect} sx={{ color: '#666' }} />
        )}

        <IconButton className="edit-icon" aria-label="edit" onClick={onClickEdit}>
          <EditOutlinedIcon />
        </IconButton>
      </div>
      <div
        className="member-card-content"
        onClick={() =>
          navigate(`/employee/${employee._id}`, {
            state: { employee }
          })
        }
      >
        <Avatar sx={{ width: 90, height: 90 }} alt={name} src={employee.profilePicture}>
          {name
            .split(' ')
            .map((n) => n[0])
            .join('')
            .toUpperCase()}
        </Avatar>
        <div className="member-info">
          <p className="member-name">{truncate(name, 20)}</p>
          <p className="member-mail">{truncate(employee.email, 20)}</p>
          <p className="member-role">{employee.role ? truncate(employee.role, 20) : ' - '}</p>
        </div>
      </div>
    </Paper>
  );
}

export default withLayoutOverlay(ClientView);
