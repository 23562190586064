import { createSlice } from '@reduxjs/toolkit';

export const alertSlice = createSlice({
  name: 'alert',
  initialState: {
    value: null
  },
  reducers: {
    successAlert: (state, action) => {
      state.value = { isSuccess: true, message: action.payload };
    },
    failureAlert: (state, action) => {
      state.value = { isSuccess: false, message: action.payload };
    }
  }
});

export const { successAlert, failureAlert } = alertSlice.actions;

export default alertSlice.reducer;
