import React, { useState } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Switch from '@mui/material/Switch';
import './form.css'; // make sure to link to the CSS file
import withLayoutOverlay from '../layout';
import { setLoading } from '../user/reducer';
import { failureAlert, successAlert } from '../alert/reducer';
import * as api from './api';
import { grey } from '@mui/material/colors';

const EmployeeForm = () => {
  const { clientId, employeeId } = useParams();
  const location = useLocation();
  const employee = (location.state || {}).employee || {};
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [employeeDetails, setEmployeeDetails] = useState({
    firstName: employee.firstName || '',
    lastName: employee.lastName || '',
    email: employee.email || '',
    mobile: employee.mobile || '',
    city: employee.city || '',
    role: employee.role || '',
    password: '',
    isMfaEnabled: employee.isMfaEnabled || false,
    profilePicture: employee.profilePicture || null
  });
  const [newProfilePic, setNewProfilePic] = useState(null);
  const [error, setError] = useState({});

  const handleChange = (e) => {
    setEmployeeDetails({ ...employeeDetails, [e.target.name]: e.target.value });
  };

  const handleMfaChange = () => {
    setEmployeeDetails({ ...employeeDetails, isMfaEnabled: !employeeDetails.isMfaEnabled });
  };

  const handleImageChange = (e) => {
    setNewProfilePic(e.target.files[0]);
  };

  const validate = () => {
    let isValid = true;
    const error = {};
    if (!employeeDetails.firstName) {
      isValid = false;
      error.firstName = 'First name is required';
    }
    if (!employeeDetails.lastName) {
      isValid = false;
      error.lastName = 'Last name is required';
    }
    if (!employeeDetails.email) {
      isValid = false;
      error.email = 'Email is required';
    } else {
      const isValidEmail = employeeDetails.email.match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );

      if (!isValidEmail) {
        isValid = false;
        error.email = 'Email is invalid';
      }
    }

    if (!employeeId && !employeeDetails.password) {
      isValid = false;
      error.password = 'Password is required';
    }

    setError(error);

    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const isValid = validate();

      if (!isValid) return;

      dispatch(setLoading(true));

      if (employeeId) {
        const { password, ...restEmployeeDetails } = employeeDetails;
        await api.update(employeeId, restEmployeeDetails);
        if (newProfilePic) await api.uploadImage(employeeId, newProfilePic);
        dispatch(successAlert('Employee updated successfully'));
      } else {
        const response = await api.create({ ...employeeDetails, clientId });
        if (newProfilePic) await api.uploadImage(response.employee._id, newProfilePic);

        dispatch(successAlert('Employee created successfully'));
      }
      navigate(-1);
    } catch (error) {
      dispatch(failureAlert(error.message));
    }
    dispatch(setLoading(false));
  };

  return (
    <Container sx={{ flexGrow: 1, width: '100vw' }}>
      <Grid
        container
        sx={{
          flexGrow: 1,
          flexDirection: 'column',
          paddingTop: '40px',
          width: '100%'
        }}
        spacing={4}
      >
        <h2>{employeeId ? 'Edit' : 'Add'} Employee Details</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-row">
            <div className="form-column">
              <div className="form-group">
                <label htmlFor="firstName">First Name <span style={{ color: 'red' }}>*</span></label>
                <input
                  type="text"
                  id="firstName"
                  name="firstName"
                  value={employeeDetails.firstName}
                  onChange={handleChange}
                />
                <span className="warning-msg" style={{ color: 'red' }}>{error.firstName}</span>
              </div>

              <div className="form-group">
                <label htmlFor="mobile">Mobile</label>
                <input type="text" id="mobile" name="mobile" value={employeeDetails.mobile} onChange={handleChange} />
              </div>

              <div className="form-group">
                <label htmlFor="city">City</label>
                <input type="text" id="city" name="city" value={employeeDetails.city} onChange={handleChange} />
              </div>

              {!employeeId && (
                <div className="form-group">
                  <label htmlFor="password">Password <span style={{ color: 'red' }}>*</span></label>
                  <input
                    type="text"
                    id="password"
                    name="password"
                    value={employeeDetails.password}
                    onChange={handleChange}
                  />
                  <span className="warning-msg" style={{ color: 'red' }}>{error.password}</span>
                </div>
              )}
            </div>

            <div className="form-column">
              <div className="form-group">
                <label htmlFor="lastName">Last Name <span style={{ color: 'red' }}>*</span></label>
                <input
                  type="text"
                  id="lastName"
                  name="lastName"
                  value={employeeDetails.lastName}
                  onChange={handleChange}
                />
                <span className="warning-msg" style={{ color: 'red' }}>{error.lastName}</span>
              </div>

              <div className="form-group">
                <label htmlFor="email">Email <span style={{ color: 'red' }}>*</span></label>
                <input type="text" id="email" name="email" value={employeeDetails.email} onChange={handleChange} />
                <span className="warning-msg" style={{ color: 'red' }}>{error.email}</span>
              </div>

              <div className="form-group">
                <label htmlFor="role">Role</label>
                <input type="text" id="role" name="role" value={employeeDetails.role} onChange={handleChange} />
              </div>
            </div>

            <div className="form-column">
              <div className="form-group">
                <label htmlFor="profilePicture">Profile Image</label>
                <input type="file" id="profilePicture" name="profilePicture" onChange={handleImageChange} />
              </div>
            </div>
          </div>

          <div className="form-row">
            <div className="form-group">
              <label htmlFor="ipAddress">2-step verification</label>
              <label htmlFor="ipAddress">
                2-step verification can be turned ON/OFF by the admin only. Here you can see the status only
              </label>
              <Switch
                checked={employeeDetails.isMfaEnabled}
                onChange={handleMfaChange}
                sx={{
                  width: 40,
                  height: 25,
                  padding: 0,
                  '& .MuiSwitch-switchBase': {
                    padding: 0,
                    margin: 0.2,
                    transitionDuration: '300ms',
                    '&.Mui-checked': {
                      transform: 'translateX(15px)',
                      color: '#fff',
                      '& + .MuiSwitch-track': {
                        backgroundColor: '#53cbfa',
                        opacity: 1,
                        border: 0,
                      },
                      '&.Mui-disabled + .MuiSwitch-track': {
                        opacity: 0.5,
                      },
                    },
                    '&.Mui-focusVisible .MuiSwitch-thumb': {
                      color: '#53cbfa',
                      border: '6px solid #fff',
                    },
                    '&.Mui-disabled .MuiSwitch-thumb': {
                      color: grey[800]
                    },
                    '&.Mui-disabled + .MuiSwitch-track': {
                      opacity: 0.7,
                    },
                  },
                  '& .MuiSwitch-thumb': {
                    boxSizing: 'border-box',
                    width: 22,
                    height: 22,
                  },
                  '& .MuiSwitch-track': {
                    borderRadius: 26 / 2,
                    backgroundColor: '#c7c7c7',
                    opacity: 1,
                  },
                }}
              />
            </div>
          </div>

          <div className="form-row">
            <div style={{ flex: 1 }}>
              <Button
                variant='outlined'
                onClick={() => navigate(-1)}
                sx={{
                  color: '#53cbfa',
                  borderColor: '#53cbfa',
                  textTransform: 'none',
                  '&:hover': {
                    borderColor: '#53cbfa',
                  },
                  padding: '5px 50px'
                }}>
                Cancel
              </Button>
            </div>
            <button type="submit">{employeeId ? 'Update employee' : 'Create employee'}</button>
          </div>
        </form>
      </Grid>
    </Container>
  );
};

export default withLayoutOverlay(EmployeeForm);
