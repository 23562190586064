/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import FolderIcon from '@mui/icons-material/Folder';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import * as api from '../api';
import { failureAlert, successAlert } from '../../alert/reducer';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2)
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  }
}));

const MoveObject = ({ open, onClose, object, type, clientId, currentFolder, fetchObjects }) => {
  const dispatch = useDispatch();
  const [curFolder, setCurFolder] = useState('');
  const [folders, setFolders] = useState([]);
  const [files, setFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchFolders = async (folderName = '') => {
    try {
      setIsLoading(true);
      const response = await api.fetchObjects(folderName, clientId);
      setFolders(response.objects.filter((o) => !o.LastModified && o.Key !== object.Key));
      setFiles(response.objects.filter((o) => o.LastModified && o.Key !== object.Key));
      setIsLoading(false);
    } catch (error) {
      console.error('fetchFolders ~ error:', error);
      dispatch(failureAlert('Failed to fetch folders, please try again'));
      setIsLoading(false);
      onClose();
    }
  };

  useEffect(() => {
    fetchFolders(curFolder);
  }, []);

  const selectFolder = (folderName) => {
    setCurFolder(folderName);
    fetchFolders(folderName);
  };

  const navigateBack = () => {
    const folderPaths = curFolder.split('/').filter((f) => f);
    folderPaths.pop();
    const prevFolder = folderPaths.join('/');
    const prevFolderPath = prevFolder ? prevFolder + '/' : '';
    setCurFolder(prevFolderPath);
    fetchFolders(prevFolderPath);
  };

  const moveObject = () => {
    const name = object.Key.split('/')
      .filter((f) => f)
      .pop();

    const newPath = `${curFolder}${name}${type === 'folder' ? '/' : ''}`;

    const isDup =
      type === 'folder' ? folders.some((o) => o.Key.includes(name)) : files.some((o) => o.Key.includes(name));
    if (isDup) {
      dispatch(failureAlert('Folder/file with this name already exists'));
      return;
    }
    updateName(newPath);
  };

  const updateName = async (name) => {
    try {
      const body = { oldName: object.Key, newName: name };

      setIsLoading(true);
      if (type === 'folder') {
        await api.renameFolder(body, clientId);
        dispatch(successAlert('Folder moved successfully'));
      } else {
        await api.renameFile(body, clientId);
        dispatch(successAlert('File moved successfully'));
      }
      setIsLoading(false);
      fetchObjects();
      onClose();
    } catch (error) {
      console.error('updateName ~ error:', error);
      dispatch(failureAlert('Failed to move'));
      setIsLoading(false);
    }
  };

  return (
    <BootstrapDialog onClose={onClose} aria-labelledby="customized-dialog-title" open={open}>
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <>
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Move "
          {object.Key.split('/')
            .filter((f) => f)
            .pop()}
          "
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogActions>
          <Button disabled={!curFolder} autoFocus onClick={navigateBack}>
            Back
          </Button>
        </DialogActions>
        <DialogContent dividers>
          <Box sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
            <nav aria-label="main mailbox folders">
              <List>
                {!folders.length && <>No folders</>}
                {folders.map((folder) => (
                  <ListItem disablePadding key={folder.Key} onClick={() => selectFolder(folder.Key)}>
                    <ListItemButton>
                      <ListItemIcon>
                        <FolderIcon fontSize="large" color="action" />
                      </ListItemIcon>
                      <ListItemText primary={folder.Key.replace(curFolder, '').replace('/', '')} />
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            </nav>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={onClose}>
            Cancel
          </Button>
          <Button autoFocus disabled={currentFolder === curFolder} onClick={moveObject}>
            Move
          </Button>
        </DialogActions>
      </>
    </BootstrapDialog>
  );
};

export default MoveObject;
