import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import ListView from './listView';

const ListViewTable = ({
  sortedObjects,
  canModify,
  selectFolder,
  openAction,
  currentFolder,
  duplicateObject,
  downloadObject,
  selectList,
  setSelectList
}) => {
  return (
    <TableContainer sx={{ paddingTop: '30px' }}>
      <Table sx={{ maxWidth: '100vw', border: 'none' }} size="small" aria-label="simple table">
        <TableHead
          sx={{
            '& .MuiTableRow-root': {
              borderTop: 'none',
              borderRight: 'none',
              borderLeft: 'none'
            }
          }}
        >
          <TableRow sx={{ 'td, th': { border: 0 }, borderBottom: '1px solid #ccc' }}>
            <TableCell></TableCell>
            <TableCell>
              <strong>Name</strong>
            </TableCell>
            <TableCell>
              <strong>Last Modified</strong>
            </TableCell>
            <TableCell>
              <strong>Size</strong>
            </TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody
          sx={{
            '& .MuiTableRow-root': {
              borderRight: 'none',
              borderLeft: 'none'
            }
          }}
        >
          {sortedObjects.map((object) => (
            <ListView
              key={object.Key}
              canModify={canModify}
              object={object}
              selectFolder={selectFolder}
              openAction={openAction}
              currentFolder={currentFolder}
              duplicateObject={duplicateObject}
              downloadObject={downloadObject}
              isSelected={selectList.includes(object.Key)}
              onSelect={() => setSelectList([...selectList, object.Key])}
              onUnSelect={() => setSelectList(selectList.filter((l) => l !== object.Key))}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ListViewTable;
