import React from 'react';
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';
import Avatar from '@mui/material/Avatar';
import Paper from '@mui/material/Paper';

const GridView = ({ clients, selectClient }) => {
  return (
    <div className="client-grid">
      {clients.map((client) => (
        <Paper
          key={client._id}
          elevation={4}
          sx={{
            borderRadius: '15px',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            display: 'flex',
            textAlign: 'center',
            padding: '20px'
          }}
          onClick={() => selectClient(client)}
        >
          <div className="client-avatar">
            <Avatar
              sx={{ width: 75, height: 75, padding: '10px' }}
              alt={client.companyName}
              src={client.profilePicture}
            >
              {client.companyName
                .split(' ')
                .map((n) => n[0])
                .join('')
                .toUpperCase()}
            </Avatar>
          </div>
          <p className="client-name">{client.companyName}</p>
          <div className="client-members">
            <PeopleOutlinedIcon sx={{ fontSize: 20 }} />
            <p>{client.employeeCount} Members</p>
          </div>
        </Paper>
      ))}
    </div>
  );
};

export default GridView;
