/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, memo } from 'react';
import { useDispatch } from 'react-redux';
import Button from '@mui/material/Button';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

import './login.css';

import * as api from './api';
import { getUser } from '../../api';
import { failureAlert, successAlert } from '../alert/reducer';
import { setUser, setLoading } from '../user/reducer';

const Login = () => {
  const [isShowOTPView, setIsShowOTPView] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [otp, setOtp] = useState(null);
  const [showPassowrdResetModal, setShowPassowrdResetModal] = useState(false);
  const dispatch = useDispatch();

  const [error, setError] = useState({ email: '', password: '', otp: '' });

  const validate = () => {
    let isValid = true;
    const error = { email: '', password: '' };
    if (!email) {
      isValid = false;
      error.email = 'Email is required';
    }
    const isValidEmail = email.match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
    if (!isValidEmail) {
      isValid = false;
      error.email = 'Email is invalid';
    }

    if (!password) {
      isValid = false;
      error.password = 'Password is required';
    }

    setError(error);

    return isValid;
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      const isValid = validate();

      if (!isValid) return;

      dispatch(setLoading(true));

      const response = await api.login({ email, password });

      if (!response.isOtpSent) {
        const token = response.token;
        localStorage.setItem('CFM-MH', token);
        const userRes = await getUser(token);
        dispatch(setUser({ ...userRes.user, token }));
      } else {
        setIsShowOTPView(true);
      }
      dispatch(successAlert(response.message));
    } catch (error) {
      dispatch(failureAlert(error.message));
    }
    dispatch(setLoading(false));
  };

  const onSubmitOtp = async (e) => {
    e.preventDefault();
    try {
      if (!otp) {
        setError({ otp: 'OTP is required' });
        return;
      }

      dispatch(setLoading(true));

      const response = await api.loginByOtp({ email, otp });

      const token = response.token;
      localStorage.setItem('CFM-MH', token);
      const userRes = await getUser(token);
      dispatch(setUser({ ...userRes.user, token }));
      dispatch(successAlert(response.message));
    } catch (error) {
      dispatch(failureAlert(error.message));
    }
    dispatch(setLoading(false));
  };

  const resendOtp = async (e) => {
    e.preventDefault();
    try {
      dispatch(setLoading(true));

      const response = await api.resendOtp({ email });

      dispatch(successAlert(response.message));
    } catch (error) {
      dispatch(failureAlert(error.message));
    }
    dispatch(setLoading(false));
  };

  const resetPassword = async () => {
    try {
      dispatch(setLoading(true));
      const response = await api.resetPassword({ email });
      setShowPassowrdResetModal(false);

      dispatch(successAlert(response.message));
    } catch (error) {
      dispatch(failureAlert(error.message));
    }
    dispatch(setLoading(false));
  };

  return (
    <Container
      component="main"
      sx={{
        height: '100vh',
        display: 'flex',
        alignItems: 'center'
      }}
    >
      <Paper
        elevation={0}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '100%',
          margin: 'auto',
          backgroundColor: 'rgba(255, 255, 255, 0.9)'
        }}
      >
        <Grid container sx={{ width: '100vw', height: '100vh' }}>
          <Grid
            item
            xs={12}
            sm={6}
            sx={{ backgroundColor: '#0c222a', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
          >
            <img src="/main-pic.png" alt="Math Head" style={{ height: '250px' }} />
            <div className="left-bottom">
              <p className="left-bottom-text">MathHead &#169; 2018 - 2024 / All Rights Reserved</p>
            </div>
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
            sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}
          >
            <div className="back-btn">
              <Button
                startIcon={<ArrowBackIosIcon />}
                style={{ color: '#807e7e', textTransform: 'none' }}
                href="https://mathhead.in/"
              >
                Back
              </Button>
            </div>
            <img src="/logo-name.png" alt="Math Head" className="logo-img" />

            {isShowOTPView ? (
              <div className="form-area">
                <div>
                  <h2>OTP Verification</h2>

                  <p className="subtext">Please enter the OTP sent to your registered email address.</p>

                  <form action="#" method="post">
                    <div className="form-group">
                      <label htmlFor="otp">Enter OTPs</label>
                      <input
                        id="otp"
                        name="otp"
                        type="number"
                        inputMode="numeric"
                        className="otp-input-field"
                        required
                        value={otp}
                        onChange={(e) => setOtp(+e.target.value)}
                      />
                      <span style={{ color: 'red', fontSize: '13px' }}>{error.otp}</span>
                    </div>

                    <Button
                      type="submit"
                      variant="contained"
                      onClick={onSubmitOtp}
                      style={{ textTransform: 'none', backgroundColor: '#53cbfa', width: '100%' }}
                    >
                      Verify
                    </Button>
                  </form>
                </div>
                <div className="contact-us-text">
                  <p>
                    Didn't receive the OTP?{' '}
                    <a href="#" className="contact-us" onClick={resendOtp}>
                      Resend OTP
                    </a>
                  </p>
                </div>
              </div>
            ) : (
              <div className="form-area">
                <div>
                  <h2>Account Login</h2>

                  <p className="subtext">If you are already a member you can login with your email and password.</p>

                  <form action="#" method="post">
                    <div className="form-group">
                      <label htmlFor="email">Email address</label>
                      <input
                        type="email"
                        id="email"
                        name="email"
                        required
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      <span style={{ color: 'red', fontSize: '13px' }}>{error.email}</span>
                    </div>
                    <div className="form-group">
                      <label htmlFor="password">Password</label>
                      <input
                        type="password"
                        id="password"
                        name="password"
                        required
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                      <span style={{ color: 'red', fontSize: '13px' }}>{error.password}</span>
                      <p className="forgot-password">
                        <a href="#" onClick={() => setShowPassowrdResetModal(true)}>
                          Forgot Password?
                        </a>
                      </p>
                    </div>
                    <Button
                      type="submit"
                      variant="contained"
                      onClick={onSubmit}
                      style={{ textTransform: 'none', backgroundColor: '#53cbfa', width: '100%' }}
                    >
                      Login
                    </Button>
                  </form>
                </div>
                <div className="contact-us-text">
                  <p>
                    Having trouble in login?{' '}
                    <a href="https://mathhead.in/contact-us/" className="contact-us">
                      Contact Us
                    </a>
                  </p>
                </div>
              </div>
            )}
          </Grid>

          <PasswordResetModal
            isOpen={showPassowrdResetModal}
            onCancel={() => setShowPassowrdResetModal(false)}
            email={email}
            setEmail={setEmail}
            error={error.email || ''}
            setError={(e) => setError({ email: e })}
            resetPassword={resetPassword}
          />
        </Grid>
      </Paper>
    </Container>
  );
};

const PasswordResetModal = ({ isOpen, onCancel, email, setEmail, error, setEmailError, resetPassword }) => {
  if (!isOpen) return null;

  const onSubmit = (e) => {
    e.preventDefault();
    if (!email) {
      setEmailError('Email is required');
      return;
    }
    resetPassword();
  };

  return (
    <div className="modal-backdrop">
      <div className="modal">
        <h2>Reset password</h2>

        <div className="form-group">
          <label htmlFor="email">Email</label>
          <input
            id="email"
            name="email"
            type="email"
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <span style={{ color: 'red', fontSize: '13px' }}>{error}</span>
        </div>
        <div className="cancel-confirm">
          <button onClick={onCancel} className="cancel-button">
            Cancel
          </button>
          <button onClick={onSubmit} className="confirm-button">
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default memo(Login);
