/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';

import './index.css';
import withLayoutOverlay from '../layout';
import GridView from './view/gridView';
import ListViewTable from './view/listViewTable';
import AddDialog from './action/addDialog';
import MultiSelectOption from './action/multiSelectOption';
import DeleteObject from './menu/deleteObject';
import RenameObject from './menu/renameObject';
import ObjectProperties from './menu/objectProperties';
import MoveObject from './menu/moveObject';
import * as api from './api';
import { failureAlert, successAlert } from '../alert/reducer';
import { setFolder } from '../user/reducer';
import { downloadFileHelper, downloadFolderHelper, downloadMultipleHelper, sortObjects } from './helper';

const Drive = ({ canModify, isAdmin, folder, isGridView, search, isAscSort, sortBy, isClientActive }) => {
  const { clientId } = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const isAccountActive = isAdmin ? (location.state || {}).isAccountActive : isClientActive;
  const [isLoading, setIsLoading] = useState(false);
  const [objects, setObjects] = useState([]);
  const [currentFolder, setCurrentFolder] = useState('');
  const [showModals, setShowModals] = useState({ rename: false, delete: false, properties: false, move: false });
  const [actionFile, setActionFile] = useState(null);
  const [actionFileType, setActionFileType] = useState(null);
  const [selectList, setSelectList] = useState([]);

  const fetchObjects = async (folderName = '') => {
    try {
      setIsLoading(true);
      const response = await api.fetchObjects(folderName, clientId);
      setObjects(response.objects);
      setIsLoading(false);
    } catch (error) {
      console.error('fetchObjects ~ error:', error);
      dispatch(failureAlert('Failed to fetch files'));
      setIsLoading(false);
    }
  };

  const searchObjects = async () => {
    try {
      setIsLoading(true);
      const response = await api.searchDrive(search, clientId);
      setObjects(response.objects);
      setIsLoading(false);
    } catch (error) {
      console.error('searchObjects ~ error:', error);
      dispatch(failureAlert('Failed to search files'));
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchObjects(folder || '');
    setCurrentFolder(folder || '');
  }, [folder]);

  useEffect(() => {
    if (search) {
      searchObjects();
    } else {
      fetchObjects(currentFolder);
    }
  }, [search]);

  const selectFolder = (folderName) => {
    dispatch(setFolder(folderName));
    setCurrentFolder(folderName);
  };

  const closeModals = () => {
    setShowModals({ rename: false, delete: false, properties: false, move: false });
    setActionFileType(null);
    setActionFile(null);
  };

  const openAction = (action, type, file) => {
    const actions = { rename: false, delete: false, properties: false, move: false };
    actions[action] = true;
    setShowModals(actions);
    setActionFileType(type);
    setActionFile(file);
  };

  const updateName = async (name) => {
    try {
      const isDuplicate = objects.some((o) => o.Key === name);
      if (isDuplicate) {
        dispatch(failureAlert('Folder/file with this name already exists'));
        return;
      }
      const body = { oldName: actionFile.Key, newName: name };

      setIsLoading(true);
      if (actionFileType === 'folder') {
        await api.renameFolder(body, clientId);
        dispatch(successAlert('Folder renamed successfully'));
      } else {
        await api.renameFile(body, clientId);
        dispatch(successAlert('File renamed successfully'));
      }
      closeModals();
      setIsLoading(false);
      fetchObjects(currentFolder);
    } catch (error) {
      console.error('updateName ~ error:', error);
      dispatch(failureAlert('Failed to rename'));
      setIsLoading(false);
    }
  };

  const deleteObject = async (e) => {
    try {
      e.preventDefault();
      setIsLoading(true);
      if (actionFileType === 'folder') {
        await api.deleteFolder(actionFile.Key, clientId);
        dispatch(successAlert('Folder deleted successfully'));
      } else {
        await api.deleteFile(actionFile.Key, clientId);
        dispatch(successAlert('File deleted successfully'));
      }
      closeModals();
      setIsLoading(false);
      fetchObjects(currentFolder);
    } catch (error) {
      console.error('deleteObject ~ error:', error);
      dispatch(failureAlert('Failed to delete'));
      setIsLoading(false);
    }
  };

  const duplicateObject = async (object, type) => {
    try {
      setIsLoading(true);
      if (type === 'folder') {
        const currentFolderName = object.Key.replace(currentFolder, '').replace('/', '');
        const duplicateFolderName = `${currentFolder || ''}Copy of ${currentFolderName}/`;
        const body = { currentName: object.Key, duplicateName: duplicateFolderName };
        await api.duplicateFolder(body, clientId);
        dispatch(successAlert('Folder duplicated successfully'));
      } else {
        const currentFileName = object.Key.replace(currentFolder, '').replace(`.${type}`, '');
        const duplicateFileName = `${currentFolder || ''}Copy of ${currentFileName}.${type}`;
        const body = { currentName: object.Key, duplicateName: duplicateFileName };
        await api.duplicateFile(body, clientId);
        dispatch(successAlert('File duplicated successfully'));
      }
      setIsLoading(false);
      fetchObjects(currentFolder);
    } catch (error) {
      console.error('duplicateObject ~ error:', error);
      dispatch(failureAlert('Error during duplication'));
      setIsLoading(false);
    }
  };

  const downloadObject = async (object, cardType) => {
    if (cardType === 'folder') {
      downloadFolder(object);
    } else {
      downloadFile(object);
    }
  };

  const downloadFolder = async (object) => {
    try {
      setIsLoading(true);

      const res = await downloadFolderHelper(object, clientId, currentFolder);

      if (res) {
        dispatch(failureAlert(res));
      } else {
        dispatch(successAlert('Folder downloaded successfully'));
      }

      setIsLoading(false);
    } catch (error) {
      console.error('downloadFolder ~ error:', error);
      dispatch(failureAlert('Error during folder download'));
      setIsLoading(false);
    }
  };

  const downloadFile = async (object) => {
    try {
      setIsLoading(true);
      const res = await downloadFileHelper(object, clientId, currentFolder);
      if (res) {
        dispatch(failureAlert(res));
      } else {
        dispatch(successAlert('File downloaded successfully'));
      }
      setIsLoading(false);
    } catch (error) {
      console.error('downloadObject ~ error:', error);
      dispatch(failureAlert('Error during file download'));
      setIsLoading(false);
    }
  };

  const downloadMultipleObjects = async (e) => {
    try {
      e.preventDefault();

      setIsLoading(true);

      const objList = objects.filter((o) => selectList.includes(o.Key));

      const res = await downloadMultipleHelper(objList, clientId, currentFolder);

      if (res) {
        dispatch(failureAlert(res));
      } else {
        dispatch(successAlert('Files downloaded successfully'));
      }

      setSelectList([]);
      setIsLoading(false);
    } catch (error) {
      console.error('downloadMultipleObjects ~ error:', error);
      dispatch(failureAlert('Error during files download'));
      setIsLoading(false);
    }
  };

  const deleteMultipleObjects = async (e) => {
    try {
      e.preventDefault();

      setIsLoading(true);

      await Promise.all(
        selectList.map((s) => {
          const obj = objects.find((o) => o.Key === s);
          if (obj.LastModified) {
            return api.deleteFile(obj.Key, clientId);
          } else {
            return api.deleteFolder(obj.Key, clientId);
          }
        })
      );
      dispatch(successAlert('Files deleted successfully'));
      setSelectList([]);
      setIsLoading(false);
      fetchObjects(currentFolder);
    } catch (error) {
      console.error('deleteMultipleObjects ~ error:', error);
      dispatch(failureAlert('Error during files deleted'));
      setIsLoading(false);
    }
  };

  const isDuplicateFolder = (name) => objects.some((o) => o.Key === `${name}/`);

  const isDuplicateFile = (name) => objects.some((o) => o.Key.includes(name));

  const sortedObjects = sortObjects(objects, isAscSort, sortBy);

  const AccountStatusAlert = ({ isAccountActive, isAdmin }) => {
    if (!isAccountActive) {
      // Account is not active
      const alertMessage = isAdmin
        ? 'Deactivated Client'
        : 'Your organization account is deactivated. Please contact admin for reactivation.';

      return (
        <Alert severity="warning" sx={{ paddingLeft: '60px', border: '2px solid #fd8625' }}>
          {alertMessage}
        </Alert>
      );
    }
  };

  return (
    <>
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <AccountStatusAlert isAccountActive={isAccountActive} isAdmin={isAdmin} />

      <MultiSelectOption
        isSelectAll={selectList.length === sortedObjects.length}
        selectList={selectList}
        setSelectList={setSelectList}
        canModify={canModify}
        deleteMultipleObjects={deleteMultipleObjects}
        downloadMultipleObjects={downloadMultipleObjects}
        onSelect={() => setSelectList(sortedObjects.map((o) => o.Key))}
        onUnSelect={() => setSelectList([])}
      />

      {!sortedObjects.length && (
        <div className="empty-folder">
          {search ? (
            <img src="/unsuccessful-search.png" alt="No Results" />
          ) : (
            <img src="/folder-empty.png" alt="Empty folder" />
          )}
          <p style={{ color: '#5e5d5d' }}>
            {search
              ? `Your search term "${search}" did not match any file or folder names`
              : 'Click on the button to Add new File/Folder'}
          </p>
        </div>
      )}

      {isGridView ? (
        <div className="drive-card-grid">
          {sortedObjects.map((object) => (
            <GridView
              key={object.Key}
              canModify={canModify}
              object={object}
              selectFolder={selectFolder}
              openAction={openAction}
              currentFolder={currentFolder}
              duplicateObject={duplicateObject}
              downloadObject={downloadObject}
              isSelected={selectList.includes(object.Key)}
              onSelect={() => setSelectList([...selectList, object.Key])}
              onUnSelect={() => setSelectList(selectList.filter((l) => l !== object.Key))}
            />
          ))}
        </div>
      ) : (
        <div>
          {sortedObjects.length && (
            <ListViewTable
              sortedObjects={sortedObjects}
              canModify={canModify}
              selectFolder={selectFolder}
              openAction={openAction}
              currentFolder={currentFolder}
              duplicateObject={duplicateObject}
              downloadObject={downloadObject}
              selectList={selectList}
              setSelectList={setSelectList}
            />
          )}
        </div>
      )}

      {canModify && (
        <AddDialog
          currentFolder={currentFolder}
          clientId={clientId}
          fetchObjects={() => fetchObjects(currentFolder)}
          isDuplicateFolder={isDuplicateFolder}
          isDuplicateFile={isDuplicateFile}
        />
      )}

      {actionFile && showModals.rename && (
        <RenameObject
          name={actionFile.Key}
          type={actionFileType}
          open={showModals.rename}
          onClose={closeModals}
          updateName={updateName}
          currentFolder={currentFolder}
        />
      )}

      {actionFile && showModals.delete && (
        <DeleteObject
          open={showModals.delete}
          name={actionFile.Key}
          type={actionFileType}
          onClose={closeModals}
          onConfirm={deleteObject}
          currentFolder={currentFolder}
        />
      )}

      {actionFile && showModals.properties && (
        <ObjectProperties
          object={actionFile}
          type={actionFileType}
          currentFolder={currentFolder}
          open={showModals.properties}
          onClose={closeModals}
        />
      )}

      {actionFile && showModals.move && (
        <MoveObject
          open={showModals.move}
          onClose={closeModals}
          object={actionFile}
          type={actionFileType}
          clientId={clientId}
          currentFolder={currentFolder}
          fetchObjects={() => fetchObjects(currentFolder)}
        />
      )}
    </>
  );
};

export default withLayoutOverlay(Drive);
