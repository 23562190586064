/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import KeyIcon from '@mui/icons-material/Key';
import Badge from '@mui/material/Badge';
import { styled } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import GridViewIcon from '@mui/icons-material/GridView';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowUpwardRoundedIcon from '@mui/icons-material/ArrowUpwardRounded';
import ArrowDownwardRoundedIcon from '@mui/icons-material/ArrowDownwardRounded';
import AddIcon from '@mui/icons-material/Add';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import { Dialog, DialogTitle, DialogActions, Divider } from '@mui/material';

import './index.css'; // This will be your CSS file
import { setUser, setFolder, setGridView } from '../user/reducer';

const FileSortBy = { 1: 'Name', 2: 'Last Modified', 3: 'Size' };

const SmallAvatar = styled(Avatar)(({ theme }) => ({
  width: 20,
  height: 20,
  border: `1px solid ${theme.palette.background.paper}`
}));

const NavBar = ({
  isAdmin,
  showAccountInfo,
  initials,
  userFirstName,
  userEmail,
  userProfilePicture,
  client = null,
  folder = null,
  isGridView,
  search,
  setSearch,
  isAscSort,
  setIsAscSort,
  sortBy,
  setSortBy
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [openDialog, setOpenDialog] = useState(false);
  const [sortAnchorEl, setSortAnchorEl] = useState(null);

  const handleSortClick = (event) => {
    setSortAnchorEl(event.currentTarget);
  };

  const handleSortClose = () => {
    setSortAnchorEl(null);
  };

  const setSortByKey = (key) => {
    setSortBy(key);
    setSortAnchorEl(null);
  };

  const handleLogoutClick = () => {
    handleClose();
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleLogoutConfirm = () => {
    handleLogout();
    setOpenDialog(false);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    localStorage.removeItem('CFM-MH');
    dispatch(setUser(null));
    navigate('/');
  };

  const selectFolder = (path) => dispatch(setFolder(path));

  const navigateToClient = (client) => {
    navigate(`/client/${client._id}`);
    dispatch(setFolder(null));
  };

  const isDrivePage = !isAdmin || location.pathname.includes('drive');
  const isMainPage = !isAdmin || location.pathname === '/';

  let folders = [];
  if (isDrivePage) {
    folders.push({ name: 'Drive', path: null });
    if (folder) {
      const folderList = folder.split('/').filter((f) => f);
      let curFolder = '';
      folderList.forEach((f) => {
        folders.push({ name: f, path: `${curFolder}${f}/` });
        curFolder = curFolder + `${f}/`;
      });
    }
  }

  const toggleGridView = () => dispatch(setGridView(!isGridView));

  return (
    <>
      <header className="dashboard-header">
        <div className="logo">
          <img src="/name.png" alt="Client Logo" className="name-img" />
          <p className="copyright-text">MathHead &#169; 2018 - 24 / All Rights Reserved</p>
        </div>

        {(isMainPage || isDrivePage) && (
          <div className="search-container">
            <SearchIcon className="search-icon" />
            <input
              type="text"
              value={search}
              placeholder={isAdmin && isMainPage ? 'Search name of organization' : 'Search files or folders by name'}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
        )}

        <div className="user-profile">
          <Box>
            {isAdmin ? (
              <Badge
                overlap="circular"
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                badgeContent={
                  <SmallAvatar sx={{ bgcolor: 'black' }}>
                    <KeyIcon sx={{ fontSize: 15 }} />
                  </SmallAvatar>
                }
                color="black"
              >
                <Avatar
                  src={userProfilePicture}
                  sx={{ width: 50, height: 50, cursor: 'pointer' }}
                  className="profile-icon"
                  onClick={handleClick}
                  aria-controls={open ? 'basic-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                >
                  {initials}
                </Avatar>
              </Badge>
            ) : (
              <Button
                onClick={handleClick}
                endIcon={<ArrowDropDownIcon />}
                sx={{
                  textTransform: 'none',
                  color: '#fff',
                  fontSize: '18px'
                }}
              >
                Hello, {userFirstName}!
              </Button>
            )}
            <Menu
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              PaperProps={{
                sx: {
                  mt: 1.5,
                  width: '18%',
                  borderRadius: '10px'
                }
              }}
            >
              <MenuItem sx={{ textAlign: 'center', justifyContent: 'center' }}>
                <Avatar src={userProfilePicture} sx={{ width: 60, height: 60, cursor: 'pointer' }}>
                  {initials}
                </Avatar>
              </MenuItem>
              <MenuItem sx={{ textAlign: 'center' }}>
                <ListItemText sx={{ color: '#737070' }}>{userEmail}</ListItemText>
              </MenuItem>
              <Divider variant="middle" />
              {!isAdmin && (
                <MenuItem onClick={showAccountInfo}>
                  <ListItemIcon>
                    <PersonOutlineOutlinedIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>Account Info</ListItemText>
                </MenuItem>
              )}
              <MenuItem onClick={handleLogoutClick}>
                <ListItemIcon>
                  <LogoutRoundedIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>Log Out</ListItemText>
              </MenuItem>
            </Menu>

            {/* Logout Confirmation Dialog */}
            <Dialog
              open={openDialog}
              onClose={handleDialogClose}
              PaperProps={{ sx: { borderRadius: '20px', padding: '10px' } }}
            >
              <DialogTitle>Are you sure you want to log out?</DialogTitle>
              <DialogActions>
                <Button onClick={handleDialogClose} color="primary">
                  Cancel
                </Button>
                <Button onClick={handleLogoutConfirm} color="primary">
                  Logout
                </Button>
              </DialogActions>
            </Dialog>
          </Box>
        </div>
      </header>

      <Box sx={{ flexGrow: 1 }}>
        <AppBar
          position="static"
          sx={{
            bgcolor: '#f8f8f8',
            height: '50px',
            display: 'flex',
            justifyContent: 'center'
          }}
          elevation={0}
        >
          <Toolbar>
            <ul className="nav-links">
              {isAdmin && (
                <li>
                  <a
                    href="/"
                    style={{
                      display: 'flex',
                      alignItems: 'flex-end',
                      textDecoration: 'none',
                      color: '#5e5d5d',  // Set your desired text color
                    }}
                  >
                    <HomeOutlinedIcon fontSize={'small'} sx={{ marginRight: '5px' }} />
                    <span>Home</span>
                  </a>
                </li>
              )}

              {client && (
                <li>
                  <a
                    onClick={() => navigateToClient(client)}
                    style={{
                      display: 'flex',
                      alignItems: 'flex-end',
                      textDecoration: 'none',
                      color: '#5e5d5d',  // Set your desired text color
                    }}
                  >
                    <ArrowForwardIosIcon sx={{ fontSize: '18px', marginRight: '5px', marginLeft: '5px' }} />
                    <span>{client.companyName}</span>
                  </a>
                </li>
              )}

              {folders.length
                ? folders.map((f) => (
                  <li key={f.name}>
                    <a
                      onClick={() => selectFolder(f.path)}
                      style={{
                        display: 'flex',
                        alignItems: 'flex-end',
                        textDecoration: 'none',
                        color: '#5e5d5d',  // Set your desired text color
                      }}
                    >
                      <ArrowForwardIosIcon sx={{ fontSize: '18px', marginRight: '5px', marginLeft: '5px' }} />
                      <span>{f.name}</span>
                    </a>
                  </li>
                ))
                : null}
            </ul>
            <div className="spacer"> </div>
            {isAdmin && isMainPage && (
              <div>
                <Button
                  variant="contained"
                  elevation="4"
                  startIcon={<AddIcon />}
                  style={{
                    color: 'black',
                    textTransform: 'none',
                    backgroundColor: 'white',
                    borderRadius: '10px'
                  }}
                  onClick={() => navigate('/client/new')}
                >
                  Add New Client
                </Button>
              </div>
            )}
            <div className="action-icons">
              {isDrivePage && (
                <div>
                  <IconButton onClick={() => setIsAscSort(!isAscSort)} aria-label="toggle-view">
                    {isAscSort ? <ArrowUpwardRoundedIcon /> : <ArrowDownwardRoundedIcon />}
                  </IconButton>
                  <Button
                    color="inherit"
                    startIcon={<ArrowDropDownIcon />}
                    onClick={handleSortClick}
                    style={{
                      color: 'black',
                      textTransform: 'none',
                      backgroundColor: 'white',
                      borderRadius: '10px',
                      width: '130px',
                      justifyContent: 'flex-start'
                    }}
                  >
                    {FileSortBy[sortBy]}
                  </Button>
                  <Menu anchorEl={sortAnchorEl} open={Boolean(sortAnchorEl)} onClose={handleSortClose}>
                    {Object.keys(FileSortBy).map((key) => (
                      <MenuItem key={key} onClick={() => setSortByKey(key)}>
                        {FileSortBy[key]}
                      </MenuItem>
                    ))}
                  </Menu>
                </div>
              )}

              {(isDrivePage || isMainPage) && (
                <IconButton onClick={toggleGridView} aria-label="toggle-view">
                  {isGridView ? <FormatListBulletedIcon /> : <GridViewIcon />}
                </IconButton>
              )}
            </div>
          </Toolbar>
        </AppBar>
      </Box>
    </>
  );
};

export default NavBar;
