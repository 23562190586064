/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import withLoadingOverlay from './routes/loading';
import Routes from './routes';
import Alert from './features/alert';
import { setUser } from './features/user/reducer';
import { getUser } from './api';
import { failureAlert } from './features/alert/reducer';

const App = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const user = useSelector((state) => state.user).user;

  const setUserData = async (token) => {
    try {
      setIsLoading(true);
      const response = await getUser(token);
      dispatch(setUser({ ...response.user, token }));
    } catch (error) {
      console.error('setUserData ~ error:', error);
      dispatch(failureAlert(error.message));
    }
    setIsLoading(false);
  };

  useEffect(() => {
    const token = localStorage.getItem('CFM-MH');
    if (token) setUserData(token);
  }, []);

  return (
    <Fragment>
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <Routes isLoggedIn={!!user} isAdmin={(user || {}).isAdmin} />
      <Alert />
    </Fragment>
  );
};

export default withLoadingOverlay(App);
