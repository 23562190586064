import JSZip from 'jszip';
import FileSaver from 'file-saver';

import * as api from './api';
import {
  FilesUploadCountLimit,
  FilesUploadSizeLimitInMB,
  FilesDownloadCountLimit,
  FilesDownloadSizeLimitInMB
} from '../../constants';

export const formatSize = (sizeInBytes) => {
  if (!sizeInBytes) return null;

  const sizeInKB = sizeInBytes / 1024;
  if (sizeInKB < 1024) return `${Math.round(sizeInKB)} KB`;

  const sizeInMB = sizeInKB / 1024;
  if (sizeInMB < 1024) return `${sizeInMB.toFixed(1)} MB`;

  const sizeInGB = sizeInMB / 1024;
  return `${sizeInGB.toFixed(1)} GB`;
};

export const formatCreatedAt = (lastModified) => {
  if (!lastModified) return null;

  const createdAt = new Date(lastModified);

  return `${createdAt.getDate()} ${createdAt.toLocaleString('en-us', {
    month: 'short'
  })}, ${createdAt.getFullYear()}, ${createdAt.getHours()}:${createdAt
    .getMinutes()
    .toString()
    .padStart(2, '0')}:${createdAt.getSeconds().toString().padStart(2, '0')}`;
};

export const downloadFileHelper = async (object, clientId, currentFolder) => {
  const fileSizeInMB = object.Size / 1024 / 1024;

  if (fileSizeInMB > FilesDownloadSizeLimitInMB)
    return `The maximum size that is allowed to download at a time, is ${FilesDownloadSizeLimitInMB}MB. Please select file with ${FilesDownloadSizeLimitInMB}MB or less for download`;

  const response = await api.downloadFile(object.Key, clientId);
  const fileResponse = await fetch(response.url);
  const blob = await fileResponse.blob();
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  const fileName = currentFolder ? object.Key.replace(currentFolder, '') : object.Key;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const downloadFolderHelper = async (object, clientId, currentFolder) => {
  const response = await api.downloadFolder(object.Key, clientId);

  if (response.urls.length > FilesDownloadCountLimit)
    return `The maximum files that is allowed to download at a time, is ${FilesDownloadCountLimit}. Please select ${FilesDownloadCountLimit} or less than ${FilesDownloadCountLimit} files for download`;

  const folderSizeInMB = response.size ? response.size / 1024 / 1024 : 0;

  if (folderSizeInMB > FilesDownloadSizeLimitInMB)
    return `The maximum size that is allowed to download at a time, is ${FilesDownloadSizeLimitInMB}MB. Please select files with ${FilesDownloadSizeLimitInMB}MB or less for download`;

  const zip = new JSZip();

  // Fetch content of each object and add it to the zip file
  await Promise.all(
    response.urls.map(async ({ key, url }) => {
      const objectResponse = await fetch(url);
      const objectBlob = await objectResponse.blob();

      const fileName = currentFolder ? key.replace(currentFolder, '') : key;

      zip.file(fileName, objectBlob);
    })
  );

  // Generate the zip file
  const zipBlob = await zip.generateAsync({ type: 'blob' });

  const folderName = object.Key.split('/')
    .filter((f) => f)
    .pop();

  // Trigger download
  FileSaver.saveAs(zipBlob, `${folderName}.zip`);
};

export const downloadMultipleHelper = async (objects, clientId, currentFolder) => {
  let size = 0;

  const response = await Promise.all(
    objects.map((object) => {
      if (object.LastModified) {
        size += object.Size;
        return api.downloadFile(object.Key, clientId);
      } else {
        return api.downloadFolder(object.Key, clientId);
      }
    })
  );
  const urls = [];

  for (const res of response) {
    res.url ? urls.push({ key: res.key, url: res.url }) : urls.push(...res.urls);
    if (!res.url) size += res.size || 0;
  }

  if (urls.length > FilesDownloadCountLimit)
    return `The maximum files that is allowed to download at a time, is ${FilesDownloadCountLimit}. Please select ${FilesDownloadCountLimit} or less than ${FilesDownloadCountLimit} files for download`;

  const folderSizeInMB = size ? size / 1024 / 1024 : 0;

  if (folderSizeInMB > FilesDownloadSizeLimitInMB)
    return `The maximum size that is allowed to download at a time, is ${FilesDownloadSizeLimitInMB}MB. Please select files with ${FilesDownloadSizeLimitInMB}MB or less for download`;

  const zip = new JSZip();

  // Fetch content of each object and add it to the zip file
  await Promise.all(
    urls.map(async ({ key, url }) => {
      const objectResponse = await fetch(url);
      const objectBlob = await objectResponse.blob();

      const fileName = currentFolder ? key.replace(currentFolder, '') : key;

      zip.file(fileName, objectBlob);
    })
  );

  // Generate the zip file
  const zipBlob = await zip.generateAsync({ type: 'blob' });

  // Trigger download
  FileSaver.saveAs(zipBlob, 'multiple.zip');
};

export const sortObjects = (objects, isAscSort, sortBy) => {
  if (+sortBy === 1) {
    const result = objects.sort((a, b) => {
      const nameA = a.Key.toUpperCase();
      const nameB = b.Key.toUpperCase();

      if (nameA < nameB) return isAscSort ? -1 : 1;

      if (nameA > nameB) return isAscSort ? 1 : -1;

      return 0;
    });

    return result;
  }

  if (+sortBy === 2) {
    const result = objects.sort((a, b) => {
      const nameA = a.LastModified;
      const nameB = b.LastModified;

      if (nameA < nameB) return isAscSort ? -1 : 1;

      if (nameA > nameB) return isAscSort ? 1 : -1;

      return 0;
    });

    return result;
  }

  if (+sortBy === 3) {
    const result = objects.sort((a, b) => {
      const nameA = a.Size;
      const nameB = b.Size;

      if (nameA < nameB) return isAscSort ? -1 : 1;

      if (nameA > nameB) return isAscSort ? 1 : -1;

      return 0;
    });

    return result;
  }
};

export const validateFileCountAndSize = (files) => {
  if (files.length > FilesUploadCountLimit)
    return `The maximum files that is allowed to upload at a time, is ${FilesUploadCountLimit}. Please select ${FilesUploadCountLimit} or less than ${FilesUploadCountLimit} files for upload`;

  const totalSizeInMB = files.reduce((sum, file) => (sum += file.size), 0) / 1024 / 1024;

  if (totalSizeInMB > FilesUploadSizeLimitInMB)
    return `The maximum size that is allowed to upload at a time, is ${FilesUploadSizeLimitInMB}MB. Please select files with ${FilesUploadSizeLimitInMB}MB or less for upload`;

  return false;
};
