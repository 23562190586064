import React from 'react';
import { useSelector } from 'react-redux';

const withLoadingOverlay = (WrappedComponent) => {
  return function WithLoadingOverlay(props) {
    const user = useSelector((state) => state.user);
    const loading = (user || {}).loading;

    return (
      <>
        {loading && <div className="loading-overlay">Loading...</div>}
        <WrappedComponent {...props} />
      </>
    );
  };
};

export default withLoadingOverlay;
