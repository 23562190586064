import React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Slide from '@mui/material/Slide';
import { Avatar, Button, Divider, List, ListItem, ListItemAvatar, ListItemText, Typography, Grid } from '@mui/material';

import { formatSize } from '../drive/helper';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AccountInfo = ({ open, onClose, user }) => {
  return (
    <Dialog
      fullScreen
      sx={{ '& .MuiDialog-paper': { width: '50%', height: '70%', borderRadius: '20px' } }}
      maxWidth="xs"
      open={open}
      TransitionComponent={Transition}
    >
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogTitle sx={{ paddingTop: '50px', paddingLeft: '40px', fontWeight: '800' }}>Account Info</DialogTitle>
      <DialogContent sx={{ display: 'flex', flexDirection: 'column', padding: '0px 40px' }}>
        <List>
          <ListItem alignItems="center" sx={{ padding: '10px 0px 20px 0px' }}>
            <ListItemAvatar sx={{ paddingRight: '20px' }}>
              <Avatar sx={{ width: 130, height: 130 }} src={user.profilePicture}>
                {user.firstName
                  .split(' ')
                  .map((n) => n[0])
                  .join('')
                  .toUpperCase()}
                {user.lastName
                  .split(' ')
                  .map((n) => n[0])
                  .join('')
                  .toUpperCase()}
              </Avatar>
            </ListItemAvatar>
            <Grid container direction="column">
              <ListItemText
                primary={
                  <Typography variant="h5">
                    {user.firstName} {user.lastName}
                  </Typography>
                }
              />
            </Grid>
          </ListItem>
          <Divider />
          <List dense={false} sx={{ padding: '15px' }}>
            <ListItem style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{ flex: 'none', width: 120, marginRight: 16 }}>
                <Typography variant="body2" color="#5e5d5d">
                  Organization
                </Typography>
              </div>
              <div style={{ flex: 'none', textAlign: 'right' }}>
                <Typography variant="body2">{user.companyName}</Typography>
              </div>
            </ListItem>
            <ListItem style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{ flex: 'none', width: 120, marginRight: 16 }}>
                <Typography variant="body2" color="#5e5d5d">
                  Email
                </Typography>
              </div>
              <div style={{ flex: 'none', textAlign: 'right' }}>
                <Typography variant="body2">{user.email}</Typography>
              </div>
            </ListItem>
            <ListItem style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{ flex: 'none', width: 120, marginRight: 16 }}>
                <Typography variant="body2" color="#5e5d5d">
                  Phone number
                </Typography>
              </div>
              <div style={{ flex: 'none', textAlign: 'right' }}>
                <Typography variant="body2">{user.mobile || '-'}</Typography>
              </div>
            </ListItem>
            <ListItem style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{ flex: 'none', width: 120, marginRight: 16 }}>
                <Typography variant="body2" color="#5e5d5d">
                  Role
                </Typography>
              </div>
              <div style={{ flex: 'none', textAlign: 'right' }}>
                <Typography variant="body2">{user.role || '-'}</Typography>
              </div>
            </ListItem>
          </List>
          <Divider />
          <List>
            <ListItem style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{ flex: 'none', width: 160, marginRight: 16 }}>
                <Typography variant="body2">2 Factor Authorization</Typography>
              </div>
              <div style={{ flex: 'none', textAlign: 'right' }}>
                <Typography variant="body2">{user.isMfaEnabled ? 'Yes' : 'No'}</Typography>
              </div>
            </ListItem>
            <ListItem style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{ flex: 'none', width: 160, marginRight: 16 }}>
                <Typography variant="body2">Allowed IPs</Typography>
              </div>
              <div style={{ flex: 'none', textAlign: 'right' }}>
                <Typography variant="body2">
                  {user.isIpLoginEnabled ? (user.ipAddresses || []).join(', ') || '-' : 'All'}
                </Typography>
              </div>
            </ListItem>
            <ListItem style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{ flex: 'none', width: 160, marginRight: 16 }}>
                <Typography variant="body2">Storage Occupied</Typography>
              </div>
              <div style={{ flex: 'none', textAlign: 'right' }}>
                <Typography variant="body2">{user.bucketSize === 0 ? 0 : formatSize(user.bucketSize)}</Typography>
              </div>
            </ListItem>
          </List>
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} sx={{ color: '#53cbfa' }}>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AccountInfo;
