import { get, post, put, postImage } from '../../api';

const url = '/clients';

export const fetchAll = () => get(`${url}`);

export const fetchByClient = (id) => get(`${url}/${id}`);

export const create = (body) => post(`${url}`, body);

export const update = (id, body) => put(`${url}/${id}`, body);

export const sendDeleteOtp = (id, body = {}) => post(`${url}/send-delete-otp/${id}`, body);

export const deleteById = (id, body) => post(`${url}/delete/${id}`, body);

export const uploadImage = (id, image) => postImage(`${url}/picture/${id}`, image);

export const getBucketsList = () => get(`${url}/s3/buckets`);

export const sendActiveStatusOtp = (id, body = {}) => post(`${url}/send-active-status-otp/${id}`, body);

export const updateActiveStatus = (id, body) => post(`${url}/update-active-status/${id}`, body);
