/* eslint-disable no-lone-blocks */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import './index.css'; // This will be your CSS file
import NavBar from './navbar';
import AccountInfo from './accountInfo';

const withLayoutOverlay = (WrappedComponent) => {
  return function WithLayoutOverlay(props) {
    const userSlice = useSelector((state) => state.user);
    const [search, setSearch] = useState('');
    const [isShowAccountInfo, setIsShowAccountInfo] = useState(false);
    const [isAscSort, setIsAscSort] = useState(true);
    const [sortBy, setSortBy] = useState(1);
    const user = userSlice.user || { firstName: '', lastName: '' };
    const client = userSlice.client || null;
    const folder = userSlice.folder || null;

    return (
      <div className="dashboard">
        <div className="dashboard-flex-container">
          {/* side nav bar */}
          <div className="main-content">
            <NavBar
              initials={user.firstName[0] || '' + user.lastName[0] || ''}
              userFirstName={user.firstName}
              userEmail={user.email}
              userProfilePicture={user.profilePicture}
              client={client}
              folder={folder}
              isAdmin={user.isAdmin}
              isGridView={userSlice.isGridView}
              search={search}
              setSearch={setSearch}
              isAscSort={isAscSort}
              setIsAscSort={setIsAscSort}
              sortBy={sortBy}
              setSortBy={setSortBy}
              showAccountInfo={() => setIsShowAccountInfo(true)}
            />

            <WrappedComponent
              {...props}
              canModify={user.isAdmin || user.isClientActive}
              isAdmin={user.isAdmin}
              isGridView={userSlice.isGridView}
              folder={folder}
              search={search}
              isAscSort={isAscSort}
              sortBy={sortBy}
              isClientActive={user.isClientActive}
            />

            {!user.isAdmin && isShowAccountInfo && (
              <AccountInfo user={user} open={isShowAccountInfo} onClose={() => setIsShowAccountInfo(false)} />
            )}
          </div>
        </div>
      </div>
    );
  };
};

export default withLayoutOverlay;

{
  /* <aside className="dashboard-sidebar">
  <h1>Hello Guru,</h1>
  <div className="storage-space">
    <p>Storage Space</p>
    <div className="storage-bar">
      <span className="storage-used">1.5 GB</span>
      <span className="storage-total">5 GB</span>
    </div>
  </div>
  <button className="new-files-button">+ New Files/Folder</button>
  <footer className="sidebar-footer">
    <p>MathHead</p>
    <p>© 2019 / All Rights Reserved</p>
  </footer>
</aside> */
}
