import { get, post } from '../../api';

const url = '/drive';

export const fetchObjects = (folder, clientId = null) => {
  const query = [];
  if (folder) query.push(`folder=${folder}`);
  if (clientId) query.push(`clientId=${clientId}`);

  const queryString = query.length ? `?${query.join('&')}` : '';

  return get(`${url}${queryString}`);
};

export const createFolder = (folderName, clientId = null) => {
  const query = [];
  if (clientId) query.push(`clientId=${clientId}`);

  const queryString = query.length ? `?${query.join('&')}` : '';

  return post(`${url}/create-folder${queryString}`, { folderName });
};

export const getUploadUrls = (files, clientId = null) => {
  const query = [];
  if (clientId) query.push(`clientId=${clientId}`);

  const queryString = query.length ? `?${query.join('&')}` : '';

  return post(`${url}/upload-url${queryString}`, { files });
};

export const renameFile = (body, clientId = null) => {
  const query = [];
  if (clientId) query.push(`clientId=${clientId}`);

  const queryString = query.length ? `?${query.join('&')}` : '';

  return post(`${url}/rename-file${queryString}`, body);
};

export const renameFolder = (body, clientId = null) => {
  const query = [];
  if (clientId) query.push(`clientId=${clientId}`);

  const queryString = query.length ? `?${query.join('&')}` : '';

  return post(`${url}/rename-folder${queryString}`, body);
};

export const deleteFile = (fileName, clientId = null) => {
  const query = [];
  if (clientId) query.push(`clientId=${clientId}`);

  const queryString = query.length ? `?${query.join('&')}` : '';

  return post(`${url}/delete-file${queryString}`, { fileName });
};

export const deleteFolder = (folderName, clientId = null) => {
  const query = [];
  if (clientId) query.push(`clientId=${clientId}`);

  const queryString = query.length ? `?${query.join('&')}` : '';

  return post(`${url}/delete-folder${queryString}`, { folderName });
};

export const duplicateFile = (body, clientId = null) => {
  const query = [];
  if (clientId) query.push(`clientId=${clientId}`);

  const queryString = query.length ? `?${query.join('&')}` : '';

  return post(`${url}/duplicate-file${queryString}`, body);
};

export const duplicateFolder = (body, clientId = null) => {
  const query = [];
  if (clientId) query.push(`clientId=${clientId}`);

  const queryString = query.length ? `?${query.join('&')}` : '';

  return post(`${url}/duplicate-folder${queryString}`, body);
};

export const downloadFile = (file, clientId = null) => {
  const query = [];
  if (file) query.push(`file=${file}`);
  if (clientId) query.push(`clientId=${clientId}`);

  const queryString = query.length ? `?${query.join('&')}` : '';

  return get(`${url}/download-file${queryString}`);
};

export const downloadFolder = (folder, clientId = null) => {
  const query = [];
  if (folder) query.push(`folder=${folder}`);
  if (clientId) query.push(`clientId=${clientId}`);

  const queryString = query.length ? `?${query.join('&')}` : '';

  return get(`${url}/download-folder${queryString}`);
};

export const searchDrive = (search, clientId = null) => {
  const query = [];
  if (search) query.push(`search=${search}`);
  if (clientId) query.push(`clientId=${clientId}`);

  const queryString = query.length ? `?${query.join('&')}` : '';

  return get(`${url}/search${queryString}`);
};
