import React, { useState, useRef } from 'react';
import Dialog from '@mui/material/Dialog';
import { useDispatch } from 'react-redux';
import { Button } from '@mui/material';

import './folderUpload.css';
import { getUploadUrls, createFolder } from '../api';
import { failureAlert, successAlert } from '../../alert/reducer';
import { validateFileCountAndSize } from '../helper';
import ConfirmDialog from '../../client/confirmDialog';

export default function FolderUpload({ open, onClose, clientId, currentFolder = '', onSuccess, isDuplicateFolder }) {
  const [isLoading, setIsLoading] = useState(false);
  const [folderFiles, setFolderFiles] = useState([]);
  const [folderName, setFolderName] = useState('');
  const [showReplaceDialog, setShowReplaceDialog] = useState(false);
  const dispatch = useDispatch();
  const folderInputRef = useRef(null);

  const uploadToS3 = async (url, file) => {
    try {
      if (!url) {
        console.error('Upload URL not present');
        return;
      }

      await fetch(url, {
        method: 'PUT',
        headers: { 'Content-Type': file.type },
        body: file
      });

      return true;
    } catch (error) {
      console.error('uploadToS3 ~ error:', error);
      return false;
    }
  };

  const handleButtonClick = () => {
    // Trigger the hidden file input when the visible button is clicked
    folderInputRef.current.click();
  };

  const uploadFiles = async (files) => {
    try {
      setIsLoading(true);

      const folderName = files[0].webkitRelativePath.split('/')[0];

      const folders = files.reduce(
        (list, file) => {
          const fp = file.webkitRelativePath.split('/');
          fp.pop();
          const fn = currentFolder + fp.join('/') + '/';
          if (!list.includes(fn)) list.push(fn);

          return list;
        },
        [currentFolder + folderName + '/']
      );

      await Promise.all(folders.map((f) => createFolder(f, clientId)));

      const fileObjects = files.map((f) => {
        return {
          name: `${currentFolder || ''}${f.webkitRelativePath}`,
          type: f.type
        };
      });

      const { urlList } = await getUploadUrls(fileObjects, clientId);

      const response = await Promise.all(
        files.map((file) => {
          const { url } = urlList.find((u) => u.name === `${currentFolder || ''}${file.webkitRelativePath}`);
          return uploadToS3(url, file);
        })
      );

      if (response.every((r) => r)) {
        dispatch(successAlert('Uploaded folder successfully'));
      } else {
        dispatch(failureAlert('Failed to upload'));
      }
    } catch (error) {
      console.error('handleChange ~ error:', error);
      dispatch(failureAlert('Failed to upload'));
    }
    onSuccess();
  };

  const handleChange = (files) => {
    if (!files.length) return;

    const err = validateFileCountAndSize(files);

    if (err) {
      dispatch(failureAlert(err));
      return;
    }

    const folderName = files[0].webkitRelativePath.split('/')[0];
    setFolderName(folderName);
    const isDup = isDuplicateFolder(currentFolder + folderName);
    if (isDup) {
      setFolderFiles(files);
      setShowReplaceDialog(true);

      return;
    }

    uploadFiles(files);
  };

  const handleFolderChange = (event) => {
    const files = Object.keys(event.target.files)
      .map((i) => event.target.files[i])
      .filter((f) => f.type);

    handleChange(files);
  };

  const cancelUpload = () => {
    setShowReplaceDialog(false);
    onClose();
  };

  return (
    <Dialog
      onClose={onClose}
      open={open}
      maxWidth="lg"
      PaperProps={{ sx: { borderRadius: '10px', padding: '25px', alignItems: 'center' } }}
    >
      {isLoading ? (
        <>Loading</>
      ) : (
        <div className="upload-folder-container">
          <label>Upload folder</label>
          <p> Click on the button to start uploading</p>
          <Button
            variant="contained"
            onClick={handleButtonClick}
            style={{
              textTransform: 'none',
              backgroundColor: '#53cbfa',
              borderRadius: '10px',
              width: '60%',
              whiteSpace: 'nowrap'
            }}
          >
            Browse Folder
          </Button>
          {/* The actual file input, hidden from view */}
          <input
            ref={folderInputRef}
            type="file"
            webkitdirectory="true"
            directory="true"
            onChange={handleFolderChange}
            style={{ display: 'none' }}
          />
        </div>
      )}

      {showReplaceDialog && (
        <ConfirmDialog
          open={showReplaceDialog}
          onCancel={cancelUpload}
          title="Upload Options"
          text={`"${folderName}" already exists in this location. Please choose one of the below options`}
          cancelText={'Cancel'}
          confirmText={'Replace'}
          onConfirm={() => uploadFiles(folderFiles)}
        />
      )}
    </Dialog>
  );
}
