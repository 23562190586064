/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import GridView from './gridView';
import ListView from './listView';
import withLayoutOverlay from '../layout';
import * as api from './api';
import { failureAlert } from '../alert/reducer';
import { setClient } from '../user/reducer';
import './index.css'; // This will be your CSS file

const Client = ({ isGridView, search }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [clients, setClients] = useState([]);
  const [searchedClients, setSearchedClients] = useState([]);

  const fetchClients = async () => {
    try {
      const response = await api.fetchAll();
      setClients(response.clients);
      setSearchedClients(response.clients);
    } catch (error) {
      dispatch(failureAlert(error.message));
    }
  };

  useEffect(() => {
    fetchClients();
  }, []);

  useEffect(() => {
    if (!search) {
      setSearchedClients(clients);
      return;
    }
    const results = clients.filter((client) => client.companyName.toLowerCase().includes(search.toLowerCase()));
    setSearchedClients(results);
  }, [search]);

  const selectClient = (client) => {
    dispatch(setClient(client));
    navigate(`/client/${client._id}`);
  };

  return (
    <main>
      {!searchedClients.length ? (
        <div className="empty-folder">
          <img src="/unsuccessful-search.png" alt="No Results" />
          <p style={{ color: '#5e5d5d', padding: '10px' }}>
            {search
              ? `Your search term "${search}" does not match with any client`
              : 'Start creating clients'}
          </p>
        </div>
      ) : null}

      {isGridView ? (
        <GridView clients={searchedClients} selectClient={selectClient} />
      ) : (
        <ListView clients={searchedClients} selectClient={selectClient} />
      )}
    </main>
  );
};

export default withLayoutOverlay(Client);
