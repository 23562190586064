import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useDispatch } from 'react-redux';

import '../index.css';
import * as api from '../api';
import FileUpload from './fileUpload';
import FolderUpload from './folderUpload';
import { failureAlert, successAlert } from '../../alert/reducer';

const AddOptions = ({ onClose, open, openNewFolderDialog, openFileUploadDialog, openFolderUploadDialog }) => {
  return (
    <Dialog onClose={onClose} open={open} maxWidth="lg" PaperProps={{ sx: { borderRadius: '20px' } }}>
      <DialogContent>
        <div className="dashboard-content">
          <Card
            elevation={1}
            sx={{
              width: 260,
              height: 260,
              boxShadow: 'none',
              border: '1px solid grey',
              borderRadius: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              paddingTop: '15px'
            }}
            onClick={openNewFolderDialog}
          >
            <CardMedia>
              <img src="/create-folder.png" alt="create folder" height="180" />
            </CardMedia>
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                Create a folder
              </Typography>
              {/* <Typography variant="body2" color="text.secondary">
              Lizards are a widespread group of squamate reptiles, with over 6,000 species
            </Typography> */}
            </CardContent>
          </Card>

          <Card
            sx={{
              width: 260,
              height: 260,
              boxShadow: 'none',
              border: '1px solid grey',
              borderRadius: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              paddingTop: '15px'
            }}
            onClick={openFileUploadDialog}
          >
            <CardMedia>
              <img src="/upload-file.png" alt="upload file" height="180" />
            </CardMedia>
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                Upload a file
              </Typography>
              {/* <Typography variant="body2" color="text.secondary">
              Lizards are a widespread group of squamate reptiles, with over 6,000 species
            </Typography> */}
            </CardContent>
          </Card>

          <Card
            sx={{
              width: 260,
              height: 260,
              boxShadow: 'none',
              border: '1px solid grey',
              borderRadius: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              paddingTop: '15px'
            }}
            onClick={openFolderUploadDialog}
          >
            <CardMedia>
              <img src="/upload-folder.png" alt="upload folder" height="180" />
            </CardMedia>
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                Upload folder
              </Typography>
            </CardContent>
          </Card>
        </div>
      </DialogContent>
    </Dialog>
  );
};

const CreateFolder = ({ open, onClose, onCreate }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        component: 'form',
        onSubmit: (event) => {
          event.preventDefault();
          const formData = new FormData(event.currentTarget);
          const formJson = Object.fromEntries(formData.entries());
          const name = formJson.name;
          onCreate(name);
        }
      }}
    >
      <DialogTitle>Create new folder</DialogTitle>
      <DialogContent sx={{ width: '300px' }}>
        <TextField
          autoFocus
          required
          margin="dense"
          id="name"
          name="name"
          label="Name"
          type="name"
          fullWidth
          variant="standard"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button type="submit">Submit</Button>
      </DialogActions>
    </Dialog>
  );
};

export default function AddDialog(props) {
  const [optionsDialog, setOptionsDialog] = useState(false);
  const [newFolderDialog, setNewFolderDialog] = useState(false);
  const [uploadFilesDialog, setUploadFilesDialog] = useState(false);
  const [uploadFolderDialog, setUploadFolderDialog] = useState(false);
  const dispatch = useDispatch();

  const openNewFolderDialog = () => {
    setOptionsDialog(false);
    setNewFolderDialog(true);
  };

  const openFileUploadDialog = () => {
    setOptionsDialog(false);
    setUploadFilesDialog(true);
  };

  const openFolderUploadDialog = () => {
    setOptionsDialog(false);
    setUploadFolderDialog(true);
  };

  const createFolder = async (name) => {
    try {
      const isDup = props.isDuplicateFolder(`${props.currentFolder || ''}${name}`);
      if (isDup) {
        dispatch(failureAlert('Folder with the same name exists. Retry with a different name'));
      } else {
        const folderName = `${props.currentFolder || ''}${name}/`;
        await api.createFolder(folderName, props.clientId);
        dispatch(successAlert('Successfully created the folder'));
        props.fetchObjects();
      }

      setNewFolderDialog(false);
    } catch (error) {
      console.error('createFolder ~ error:', error);
      dispatch(failureAlert(error.message));
    }
  };

  const onFileUpload = () => {
    props.fetchObjects();
    setUploadFilesDialog(false);
  };

  const onFolderUpload = () => {
    props.fetchObjects();
    setUploadFolderDialog(false);
  };

  return (
    <div>
      <button className="add-button" onClick={() => setOptionsDialog(true)}>
        <AddCircleOutlinedIcon onClick={() => setOptionsDialog(true)} />
      </button>

      <AddOptions
        open={optionsDialog}
        onClose={() => setOptionsDialog(false)}
        openNewFolderDialog={openNewFolderDialog}
        openFileUploadDialog={openFileUploadDialog}
        openFolderUploadDialog={openFolderUploadDialog}
      />

      {newFolderDialog && (
        <CreateFolder open={newFolderDialog} onClose={() => setNewFolderDialog(false)} onCreate={createFolder} />
      )}

      {uploadFilesDialog && (
        <FileUpload
          open={uploadFilesDialog}
          onClose={() => setUploadFilesDialog(false)}
          onSuccess={onFileUpload}
          currentFolder={props.currentFolder}
          clientId={props.clientId}
          isDuplicateFile={props.isDuplicateFile}
        />
      )}

      {uploadFolderDialog && (
        <FolderUpload
          open={uploadFolderDialog}
          onClose={() => setUploadFolderDialog(false)}
          onSuccess={onFolderUpload}
          currentFolder={props.currentFolder}
          clientId={props.clientId}
          isDuplicateFolder={props.isDuplicateFolder}
        />
      )}
    </div>
  );
}
