import React from 'react';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';

import '../index.css';
import { formatSize, formatCreatedAt } from '../helper';

const ObjectProperties = ({ object, type, currentFolder, onClose, open }) => {
  let name = object.Key.replace(currentFolder, '');
  if (type === 'folder') name = name.replace('/', '');

  const size = object.Size ? formatSize(object.Size) : null;
  const createdAt = object.LastModified ? formatCreatedAt(object.LastModified) : null;

  return (
    <Dialog
      sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435, borderRadius: '20px' } }}
      maxWidth="xs"
      open={open}
    >
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogTitle sx={{ paddingTop: '50px', paddingLeft: '40px', fontWeight: '800' }}>Properties</DialogTitle>
      <DialogContent sx={{ display: 'flex', flexDirection: 'column' }}>
        <List>
          <ListItem style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ flex: 'none', width: 120, marginRight: 16 }}>
              <Typography variant="body2">Name</Typography>
            </div>
            <div style={{ flex: 'none', textAlign: 'right' }}>
              <Typography variant="body2">{name}</Typography>
            </div>
          </ListItem>

          {/* <ListItem style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ flex: 'none', width: 120, marginRight: 16 }}>
              <Typography variant="body2">Description</Typography>
            </div>
            <div style={{ flex: 'none', textAlign: 'right' }}>
              <Typography variant="body2"></Typography>
            </div>
          </ListItem> */}
          <Divider variant="middle" sx={{ margin: '8px 15px' }}></Divider>
          <ListItem style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ flex: 'none', width: 120, marginRight: 16 }}>
              <Typography variant="body2">Type</Typography>
            </div>
            <div style={{ flex: 'none', textAlign: 'right' }}>
              <Typography variant="body2">{type}</Typography>
            </div>
          </ListItem>
          <ListItem style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ flex: 'none', width: 120, marginRight: 16 }}>
              <Typography variant="body2">Size</Typography>
            </div>
            <div style={{ flex: 'none', textAlign: 'right' }}>
              <Typography variant="body2">{size || '-'}</Typography>
            </div>
          </ListItem>
          <ListItem style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ flex: 'none', width: 120, marginRight: 16 }}>
              <Typography variant="body2">Location</Typography>
            </div>
            <div style={{ flex: 'none', textAlign: 'right' }}>
              <Typography variant="body2">{currentFolder || '/'}</Typography>
            </div>
          </ListItem>
          <ListItem style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ flex: 'none', width: 120, marginRight: 16 }}>
              <Typography variant="body2">Created</Typography>
            </div>
            <div style={{ flex: 'none', textAlign: 'right' }}>
              <Typography variant="body2">{createdAt || '-'}</Typography>
            </div>
          </ListItem>
          {/* <ListItem style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ flex: 'none', width: 120, marginRight: 16 }}>
              <Typography variant="body2">Created by</Typography>
            </div>
            <div style={{ flex: 'none', textAlign: 'right' }}>
              <Typography variant="body2"></Typography>
            </div>
          </ListItem> */}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} sx={{ color: '#53cbfa' }}>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ObjectProperties;
