import React from 'react';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import FolderIcon from '@mui/icons-material/Folder';
import PictureAsPdfOutlinedIcon from '@mui/icons-material/PictureAsPdfOutlined';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import IconButton from '@mui/material/IconButton';
import CheckBoxOutlineBlankRoundedIcon from '@mui/icons-material/CheckBoxOutlineBlankRounded';
import CheckBoxRoundedIcon from '@mui/icons-material/CheckBoxRounded';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';

import ObjectMenu from '../menu/objectMenu';
import { formatSize, formatCreatedAt } from '../helper';

const getCardTypeIcon = (cardType) => {
  switch (cardType.toLowerCase()) {
    case 'folder':
      return <FolderIcon color="action" />;
    case 'pdf':
      return <PictureAsPdfOutlinedIcon />;
    case 'png':
    case 'jpeg':
    case 'svg':
    case 'jpg':
    case 'gif':
      return <ImageOutlinedIcon />;

    default:
      return <InsertDriveFileIcon />;
  }
};

const ListView = ({
  canModify,
  object,
  selectFolder,
  openAction,
  currentFolder,
  duplicateObject,
  downloadObject,
  isSelected,
  onSelect,
  onUnSelect
}) => {
  const { Key, LastModified } = object;
  const type = Key.split('.').pop();
  const cardType = type.includes('/') ? 'folder' : type;

  const objectName =
    cardType === 'folder' ? Key.replace(currentFolder, '').replace('/', '') : Key.replace(currentFolder, '');

  const onClickObject = () => {
    if (cardType !== 'folder') return;
    selectFolder(Key);
  };

  return (
    <TableRow key={objectName} sx={{ 'td, th': { border: 0 }, borderBottom: '1px solid #ccc' }}>
      <TableCell>
        {isSelected ? (
          <CheckBoxRoundedIcon onClick={onUnSelect} sx={{ color: '#53cbfa' }} />
        ) : (
          <CheckBoxOutlineBlankRoundedIcon onClick={onSelect} />
        )}
      </TableCell>
      <TableCell onClick={onClickObject} sx={{ display: 'flex', alignItems: 'center' }}>
        <IconButton sx={{ fontSize: '12px', marginRight: '5px' }}>{getCardTypeIcon(cardType)}</IconButton>
        {objectName}
      </TableCell>
      <TableCell onClick={onClickObject}>{LastModified ? formatCreatedAt(LastModified) : '-'}</TableCell>
      <TableCell onClick={onClickObject}>{object.Size ? formatSize(object.Size) : '-'}</TableCell>
      <TableCell>
        <IconButton>
          <ObjectMenu
            object={object}
            cardType={cardType}
            openAction={openAction}
            duplicateObject={duplicateObject}
            downloadObject={downloadObject}
            canModify={canModify}
          />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

export default ListView;
