import { get, post, put, destroy, postImage } from '../../api';

const url = '/employees';

export const create = (body) => post(`${url}`, body);

export const update = (id, body) => put(`${url}/${id}`, body);

export const getById = (id) => get(`${url}/by-id/${id}`);

export const deleteEmployee = (id) => destroy(`${url}/${id}`);

export const uploadImage = (id, image) => postImage(`${url}/picture/${id}`, image);
