import { post } from '../../api';

const url = '/auth';

export const login = (body) => post(`${url}/login`, body);

export const loginByOtp = async (body) => post(`${url}/otp-login`, body);

export const resendOtp = async (body) => post(`${url}/resend-otp`, body);

export const resetPassword = async (body) => post(`${url}/reset-password`, body);

export const setPassword = async (body) => post(`${url}/set-password`, body);
