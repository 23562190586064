import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import FolderIcon from '@mui/icons-material/Folder';
import CardHeader from '@mui/material/CardHeader';
import PictureAsPdfOutlinedIcon from '@mui/icons-material/PictureAsPdfOutlined';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import CheckBoxOutlineBlankRoundedIcon from '@mui/icons-material/CheckBoxOutlineBlankRounded';
import CheckBoxRoundedIcon from '@mui/icons-material/CheckBoxRounded';
import Popover from '@mui/material/Popover';

import '../index.css';
import ObjectMenu from '../menu/objectMenu';

const getCardTypeIcon = (cardType, onClickObject) => {
  switch (cardType.toLowerCase()) {
    case 'folder':
      return <FolderIcon fontSize="large" color="action" onClick={onClickObject} />;
    case 'pdf':
      return <PictureAsPdfOutlinedIcon fontSize="large" />;
    case 'png':
    case 'jpeg':
    case 'svg':
    case 'jpg':
    case 'gif':
      return <ImageOutlinedIcon fontSize="large" />;

    default:
      return <InsertDriveFileIcon fontSize="large" />;
  }
};

const GridView = ({
  canModify,
  object,
  selectFolder,
  openAction,
  currentFolder,
  duplicateObject,
  downloadObject,
  isSelected,
  onSelect,
  onUnSelect
}) => {
  const { Key, LastModified } = object;
  const createdAt = new Date(LastModified);
  const type = Key.split('.').pop();
  const cardType = type.includes('/') ? 'folder' : type;
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const objectName =
    cardType === 'folder' ? Key.replace(currentFolder, '').replace('/', '') : Key.replace(currentFolder, '');

  const onClickObject = () => {
    if (cardType !== 'folder') return;
    selectFolder(Key);
  };

  return (
    <Card sx={{ width: 200, boxShadow: 4, borderRadius: 4, '&:hover': { backgroundColor: '#F5F5F5' } }}>
      <CardActionArea>
        <CardHeader
          avatar={getCardTypeIcon(cardType, onClickObject)}
          action={
            <div style={{ padding: '10px 5px' }}>
              {isSelected ? (
                <CheckBoxRoundedIcon onClick={onUnSelect} sx={{ color: '#53cbfa' }} />
              ) : (
                <CheckBoxOutlineBlankRoundedIcon onClick={onSelect} />
              )}
              <ObjectMenu
                object={object}
                cardType={cardType}
                openAction={openAction}
                duplicateObject={duplicateObject}
                downloadObject={downloadObject}
                canModify={canModify}
              />

            </div>
          }
        />

        <CardContent onClick={onClickObject}>
          <Typography
            gutterBottom
            variant="subtitle1"
            component="div"
            noWrap
            aria-owns={open ? 'mouse-over-popover' : undefined}
            aria-haspopup="true"
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
            className="truncate">
            {objectName}
          </Typography>
          <Popover
            id="mouse-over-popover"
            sx={{
              pointerEvents: 'none',
            }}
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            PaperProps={{
              sx: {
                width: '250px',
                padding: '5px'
              }
            }}
            onClose={handlePopoverClose}
            disableRestoreFocus
          >
            <Typography sx={{ p: 1, fontSize: '12px', wordWrap: 'break-word' }}>{objectName}</Typography>
          </Popover>
          {LastModified && (
            <Typography variant="caption" color="text.secondary">
              {createdAt.getDate()} {createdAt.toLocaleString('en-us', { month: 'short' })}, {createdAt.getFullYear()}
            </Typography>
          )}
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default GridView;
