import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

// common
import ErrorBoundary from './errorBoundary';
import NotFound from './pageNotFound';

// auth
import Auth from '../features/auth/login';
import ResetPassword from '../features/auth/resetPassword';

// admin
import Client from '../features/client';
import ClientForm from '../features/client/form';
import ClientView from '../features/client/view';
import EmployeeForm from '../features/employee/form';
import EmployeeView from '../features/employee/view';
import Drive from '../features/drive';

export default function AppRoutes({ isLoggedIn = false, isAdmin = false }) {
  return (
    <BrowserRouter>
      <ErrorBoundary>
        <Routes>
          {isLoggedIn ? (
            <>
              {isAdmin ? (
                <>
                  <Route path="/" element={<Client />} />
                  <Route path="/client" element={<Client />} />
                  <Route path="/client/:clientId" element={<ClientView />} />
                  <Route path="/client/new" element={<ClientForm />} />
                  <Route path="/client/edit/:clientId" element={<ClientForm />} />
                  <Route path="/employee/:employeeId" element={<EmployeeView />} />
                  <Route path="/employee/new/:clientId" element={<EmployeeForm />} />
                  <Route path="/employee/edit/:employeeId" element={<EmployeeForm />} />
                  <Route path="/employee/edit/:employeeId" element={<EmployeeForm />} />
                  <Route path="/drive/:clientId" element={<Drive />} />
                </>
              ) : (
                <Route path="/" element={<Drive />} />
              )}

              <Route path="*" element={<NotFound />} />
            </>
          ) : (
            <>
              <Route path="/" element={<Auth />} />
              <Route path="/reset-password/:token" element={<ResetPassword />} />
              <Route path="*" element={<NotFound />} />
            </>
          )}
        </Routes>
      </ErrorBoundary>
    </BrowserRouter>
  );
}
